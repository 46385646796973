// @flow
import React from 'react';
import { useSearchParamsContext } from './SearchParamsProvider';

type Props = {
  rodsCount: number,
  reelsCount: number,
  linesCount: number,
  luresCount: number,
};

const FilterButtons = (props: Props): React$Node => {
  const { setTackleType, tackleType } = useSearchParamsContext();

  const handleClickFilterButton = type => {
    if (tackleType != type) {
      setTackleType(type);
    }
  };

  const buttonClass = (type, count) => {
    const baseClass = 'filter_button button_label fw_400';
    if (tackleType === type) {
      return `active ${baseClass}`;
    }

    if (count === 0) {
      return `disabled ${baseClass}`;
    }

    return baseClass;
  };
  return (
    <div data-scope-path="tacklebox/search/filter_buttons">
      <button
        className={buttonClass('lure', props.luresCount)}
        disabled={props.luresCount === 0 ? 'disabled' : ''}
        onClick={() => handleClickFilterButton('lure')}
      >
        {`ルアー ${props.luresCount}件`}
      </button>
      <button
        className={buttonClass('rod', props.rodsCount)}
        disabled={props.rodsCount === 0 ? 'disabled' : ''}
        onClick={() => handleClickFilterButton('rod')}
      >
        {`ロッド ${props.rodsCount}件`}
      </button>
      <button
        className={buttonClass('reel', props.reelsCount)}
        disabled={props.reelsCount === 0 ? 'disabled' : ''}
        onClick={() => handleClickFilterButton('reel')}
      >
        {`リール ${props.reelsCount}件`}
      </button>
      <button
        className={buttonClass('line', props.linesCount)}
        disabled={props.linesCount === 0 ? 'disabled' : ''}
        onClick={() => handleClickFilterButton('line')}
      >
        {`ライン ${props.linesCount}件`}
      </button>
    </div>
  );
};

export default FilterButtons;

// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import type { TackleLine } from '../../models/tackle_lines/TackleLine';
import { TACKLE_LINE_COLUMN_DEFINITIONS } from '../../models/tackle_lines/TackleLine';
import SpecModal from './SpecModal';

type Props = {
  tackleLine: TackleLine,
};

function LineSpeckCarouselRoot(props: Props): Node {
  return <LineSpeckCarousel {...props} />;
}

function LineSpeckCarousel({ tackleLine }: Props) {
  const [active, setActive] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  const onScroll = () => {
    setActive(true);
  };

  const onOpen = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <section>
        <div className="display_flex justify_between align_center">
          <h2 className="headline_sub mb_3">
            スペック詳細
            <i className="fa fa-question-circle-o" onClick={onOpen}></i>
          </h2>
        </div>

        <div className="mt_3">
          <div data-scope-path="tacklebox/models/speck_carousel_component">
            <div className="models_speck_carousel_container">
              <div className="models_speck_carousel" onScroll={onScroll}>
                {TACKLE_LINE_COLUMN_DEFINITIONS.map(column => {
                  if (tackleLine[column.key]) {
                    return (
                      <Item key={column.key} label={column.label}>
                        {tackleLine[column.key]}
                      </Item>
                    );
                  }
                })}
              </div>

              <div
                className={`models_speck_carousel_guide ${
                  active ? 'active' : ''
                }`}
              >
                <i className="fa fa-chevron-right" aria-hidden="true"></i>
              </div>
            </div>
            {tackleLine.remarks && (
              <div className="remark">
                <div className="remark_label">備考</div>
                <div className="remark_content">{tackleLine.remarks}</div>
              </div>
            )}
          </div>
        </div>
      </section>

      <SpecModal visible={visible} onClose={onClose} />
    </>
  );
}

function Item({
  label,
  children,
}: {
  label: string,
  children: React$Node,
}): React$Node {
  return (
    <div className="carousel_item">
      <div className="models_speck_carousel_item_header">{label}</div>
      {children}
    </div>
  );
}

export default LineSpeckCarouselRoot;

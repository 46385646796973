// @flow
import React, { createContext, useContext, useState } from 'react';
import type { Node } from 'react';
import type { ModalContextType } from './ModalContextType';

type Props = {
  children: React$Node,
};

const ModalContext = createContext({});
export const useModalContext = (): ModalContextType => useContext(ModalContext);

export const ModalProvider = ({ children }: Props): Node => {
  const [visibleFilterModal, setVisibleFilterModal] = useState<boolean>(false);
  const toggleModal = () => {
    setVisibleFilterModal(!visibleFilterModal);
  };

  const value = {
    toggleModal,
    visibleFilterModal,
    setVisibleFilterModal,
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

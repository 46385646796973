// @flow
import React from 'react';
import type { Node } from 'react';
import type { Advertisement } from '../../models/advertisements/Advertisement';
import amazonImage from '../../assets/images/views/advertisements/amazon.png';
import yahooImage from '../../assets/images/views/advertisements/yahoo.png';
import rakutenImage from '../../assets/images/views/advertisements/rakuten.png';
type Props = {
  advertisement: Advertisement,
  index: number,
  base_price: ?number,
};

function ShopCard({ advertisement, index, base_price }: Props): Node {
  const onClick = (ad: Advertisement) => {
    // $FlowFixMe
    clickAdvertisement(ad.id);
  };
  const sponsorImage = sponsorName => {
    switch (sponsorName) {
      case 'Amazon':
        return amazonImage;
      case 'Yahoo':
        return yahooImage;
      case 'Rakuten':
        return rakutenImage;
    }
  };
  const visibleLowestPriceLabel = visibleLowestPrice({
    advertisement: advertisement,
    index: index,
    base_price: base_price,
  });
  return (
    <div
      data-scope-path="react_components/tacklebox/models/shop_card_component"
      className={`${visibleLowestPriceLabel ? 'lowest_price' : ''}`}
    >
      <div className="shop_card_contents">
        <div className="shop_card_labels">
          {visibleLowestPriceLabel && (
            <span className="label_accent">最安値</span>
          )}
          {advertisement.is_free_shipping ? (
            <span className="label_rating">送料無料</span>
          ) : (
            <span className="label_default">送料別途</span>
          )}
        </div>
        <h2 className="shop_card_head">
          <div className={`shop_card_price shop_card_price${index}`}>
            {advertisement.price
              ? `${advertisement.price.toLocaleString()}円`
              : ` - 円`}
          </div>
        </h2>
        <div className="shop_card_shop">
          <figure className="shop_card_shop_thumbnail">
            <img
              src={sponsorImage(advertisement.sponsor.name)}
              alt="マケプレ名"
            ></img>
          </figure>
          <h3 className={`shop_card_shop_name shop_card_shop_name${index}`}>
            <strong>{advertisement.sponsor.name}</strong>
            <div className="line_clamp_1">{advertisement.shop_name}</div>
          </h3>
        </div>
      </div>
      <div className="shop_card_link">
        <a
          className={`shop_card_link_text ga4_af_click${index}`}
          href={advertisement.url}
          onClick={() => onClick(advertisement)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-external-link" aria-hidden="true"></i>
          &nbsp;購入する
        </a>
      </div>
    </div>
  );
}

function visibleLowestPrice({ advertisement, index, base_price }): boolean {
  // ruby側でadvertisementsが金額の昇順に並べられているため、index === 1のような分岐が可能
  if (index === 1) {
    if (!base_price) {
      // 個別販売してない製品は配列の先頭が最安値製品
      return true;
    }

    if (advertisement.price && base_price >= advertisement.price) {
      return true;
    }
  }

  return false;
}
export default ShopCard;

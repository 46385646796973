// @flow
import React from 'react';
import type { Node } from 'react';
import { useModalContext } from './ModalProvider';
import { useSearchParamsContext } from './SearchParamsProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faSliders } from '@fortawesome/free-solid-svg-icons';

const FishModal = (): Node => {
  const { visibleFishModal, setVisibleFishModal } = useModalContext();

  const {
    selectedFishes,
    filteredFishes,
    fishKeywords,
    updateSelectedFishes,
    handleKeywords,
    filterItems,
  } = useSearchParamsContext();

  return (
    <div data-scope-path="tacklebox/common/modal">
      <div
        className={`back-drop ${visibleFishModal ? 'back-drop-active' : ''}`}
        onClick={() => setVisibleFishModal(false)}
      />
      <div className={`modal ${visibleFishModal ? 'modal-active' : ''}`}>
        <div className="modal-header">
          <FontAwesomeIcon
            icon={faAngleDown}
            size="xs"
            className="drop_down_icon"
          />
          魚種で絞り込む
        </div>
        <div className="modal-body">
          <input
            type="text"
            className="pure-u-1"
            placeholder="キーワード"
            value={fishKeywords}
            onChange={e => handleKeywords(e.target.value, 'fish')}
            onKeyDown={e => filterItems(e.key, 'fish')}
          />
          <div className="display_flex flex_wrap mt_5 align_center fz_sub fc_sub">
            <div className="mr_3">選択中</div>
            {selectedFishes.map(fish => (
              <div className="mr_3" key={fish.id}>
                {fish.name}
              </div>
            ))}
            <a href="#######" className="fc_sub">
              <i className="fa fa-times fz_sub-headline" aria-hidden="true"></i>
              条件をクリア
            </a>
          </div>
        </div>
        <div className="modal-scroll-body">
          <div className="checkbox_group display_flex flex_wrap n_m_2">
            {filteredFishes.map(fish => (
              <div
                className={`checkbox p_2 ${
                  selectedFishes.find(
                    selectedFish => selectedFish.id === fish.id
                  )
                    ? 'active'
                    : ''
                }`}
                key={fish.id}
                onClick={() => updateSelectedFishes(fish)}
              >
                <input type="checkbox" />
                <label>
                  <span className="label_text">{fish.name}</span>
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="modal-body"></div>
        <div className="modal-footer">
          <a href="javascript:void(0);" className="btn_primary">
            <FontAwesomeIcon icon={faSliders} size="xs" />
            この条件で絞り込む
          </a>
        </div>
      </div>
    </div>
  );
};

export default FishModal;

// @flow
import React, { useState } from 'react';

import RodFavoriteClient from '../../models/favorites/RodFavoriteClient';

type Props = {
  tackleRodId: number,
  isFollowing: ?boolean,
  isSignedIn: boolean,
  token: string,
};

function RodFarvoiteButtonRoot(props: Props) {
  return <RodFarvoiteButton {...props} />;
}

function RodFarvoiteButton({
  tackleRodId,
  isFollowing,
  isSignedIn,
  token,
}: Props) {
  const [following, setFollowing] = useState(isFollowing);

  const onClick = () => {
    const lineFavoriteClient = new RodFavoriteClient();
    if (following) {
      lineFavoriteClient
        .removeFavorite(tackleRodId, token)
        .then(() => setFollowing(false))
        .catch(error => console.error(error));
    } else {
      lineFavoriteClient
        .addFavorite(tackleRodId, token)
        .then(() => setFollowing(true))
        .catch(error => console.error(error));
    }
  };

  return (
    <>
      {isSignedIn ? (
        <a
          data-scope-path="tacklebox/commons/favorite_button_component"
          className={`ga4_click2 ${following ? 'active' : ''}`}
          onClick={onClick}
        >
          きになる
        </a>
      ) : (
        <a
          href="/users/sign_in"
          data-scope-path="tacklebox/commons/favorite_button_component"
          className="ga4_click2"
        >
          きになる
        </a>
      )}
    </>
  );
}

export default RodFarvoiteButtonRoot;

// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import { useSearchParamsContext } from './SearchParamsProvider';
import type { FishingMethod } from '../../models/fishing_methods/FishingMethod';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faCheck,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

type Props = {
  onPressBack: () => void,
  onCloseModal: () => void,
};

export const FishingMethodModal = ({
  onPressBack,
  onCloseModal,
}: Props): Node => {
  const {
    selectedFishingMethods,
    selectedFishingMethodNames,
    filteredFishingMethods,
    setSelectedFishingMethods,
    setSelectedFishingMethodNames,
    fishingMethodKeywords,
    handleKeywords,
    filterItems,
  } = useSearchParamsContext();
  const [fishingMethods, setFishingMethods] = useState<Array<FishingMethod>>(
    selectedFishingMethods
  );
  const [fishingMethodNames, setFishingMethodNames] = useState<Array<string>>(
    selectedFishingMethodNames
  );

  const updateSelectedFishingMethods = (value: FishingMethod) => {
    if (fishingMethods.find(fishingMethod => fishingMethod.id === value.id)) {
      const filteredFishingMethods = fishingMethods.filter(
        fishingMethod => fishingMethod.id !== value.id
      );
      const filteredItems = fishingMethods.filter(
        fishingMethod => fishingMethod !== value.name
      );
      setFishingMethods(filteredFishingMethods);
      setFishingMethodNames(
        filteredItems.map(fishingMethod => fishingMethod.name)
      );
    } else {
      setFishingMethods([...fishingMethods, value]);
      setFishingMethodNames([...fishingMethodNames, value.name]);
    }
  };

  const onPressClear = () => {
    setFishingMethods([]);
    setFishingMethodNames([]);
  };

  const onSubmit = () => {
    setSelectedFishingMethods(fishingMethods);
    setSelectedFishingMethodNames(fishingMethodNames);
    onPressBack();
  };

  return (
    <div data-scope-path="tacklebox/common/modal">
      <div className={`modal modal-active`}>
        <div className="modal-header">
          <div onClick={onPressBack} className="modal-back">
            <FontAwesomeIcon
              icon={faAngleLeft}
              size="xs"
              className="angle_left_icon"
            />
            戻る
          </div>
          <div>釣り方で絞り込む</div>
          <FontAwesomeIcon
            icon={faXmark}
            size="xs"
            className="close_icon"
            onClick={() => onCloseModal()}
          />
        </div>
        <div className="modal-body">
          <input
            type="text"
            className="pure-u-1"
            placeholder="キーワード"
            value={fishingMethodKeywords}
            onChange={e => handleKeywords(e.target.value, 'fishingMethod')}
            onKeyDown={e => filterItems(e.key, 'fishingMethod')}
          />
          <div className="display_flex flex_wrap mt_5 align_center fz_sub fc_sub">
            <div className="mr_3">選択中</div>
            {fishingMethods.map(fish => (
              <div className="mr_3" key={fish.id}>
                {fish.name}
              </div>
            ))}
            <span onClick={onPressClear} className="fc_sub">
              <i className="fa fa-times fz_sub-headline" aria-hidden="true"></i>
              条件をクリア
            </span>
          </div>
        </div>
        <div className="modal-scroll-body">
          <div className="display_flex flex_wrap inner">
            {filteredFishingMethods.map(fishingMethod => (
              <div
                className={`fishing-method-checkbox ${
                  fishingMethods.find(
                    selectedFishingMethod =>
                      selectedFishingMethod.id === fishingMethod.id
                  )
                    ? 'active'
                    : ''
                }`}
                key={fishingMethod.id}
                onClick={() => updateSelectedFishingMethods(fishingMethod)}
              >
                <input type="checkbox" />
                <label>
                  <span className="method_name">{fishingMethod.name}</span>
                  <FontAwesomeIcon
                    icon={faCheck}
                    size="xs"
                    className="check_icon"
                  />
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            onClick={onSubmit}
            className="btn_primary submit_button"
          >
            <FontAwesomeIcon icon={faCheck} size="xs" />
            決定
          </button>
        </div>
      </div>
    </div>
  );
};

export default FishingMethodModal;

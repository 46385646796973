// @flow
import React from 'react';
import { useModalContext } from './ModalProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders } from '@fortawesome/free-solid-svg-icons';

const FilterModalButton = (): React$Node => {
  const { toggleModal } = useModalContext();
  return (
    <div
      data-scope-path="tacklebox/search/filter_modal_button"
      onClick={() => toggleModal()}
    >
      <FontAwesomeIcon icon={faSliders} size="xs" />
      <div>絞り込み</div>
    </div>
  );
};

export default FilterModalButton;

// @flow
import React from 'react';
import Stars from '../reviews/Stars';
import type { PrototypeTackleLure } from '../../models/prototype_tackle_lures/PrototypeTackleLure.js';

type Props = {
  lure: PrototypeTackleLure,
};

function PrototypeLureCard({ lure }: Props): React$Node {
  return (
    <section data-scope-path="tacklebox/search/model_card">
      <a href={`/lures/${lure.id}`} className="card_cover">
        <img src={lure.image_url} alt="" className="" />
        <div className="cover_details">
          <h1>
            <div className="maker">{lure.maker.name_en}</div>
            <div className="series">{lure.name}</div>
          </h1>
          <Stars score={lure.total_score} starSize="md" />
          <div className="lowest_price">
            最安値
            <div className="price">{lure.price.toLocaleString('ja-JP')}</div>
          </div>
        </div>
      </a>
    </section>
  );
}

export default PrototypeLureCard;

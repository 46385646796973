// @flow
import React from 'react';

type Props = {
  visible: boolean,
  onClose: () => void,
  modelId: number,
  onRemove: number => void,
};

const FavoriteModal = ({
  visible,
  onClose,
  modelId,
  onRemove,
}: Props): React$Node => {
  return (
    <>
      <div
        data-scope-path="tacklebox/common/responsive_modal_component"
        className={visible ? 'visible' : ''}
      >
        <div className="backdrop" onClick={onClose}></div>

        <div className="modal_container">
          <div className="modal_body">
            <p className="mb_5 text_center fz_sub-headline weight_bold">
              おきにいりから除外しますか？
            </p>

            <div className="display_flex justify_center gap_3">
              <a onClick={onClose} className="btn_secondary pure-u-1">
                キャンセル
              </a>
              <a
                onClick={() => {
                  onRemove(modelId);
                }}
                className="btn_primary pure-u-1"
              >
                OK
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const MemoizedFavoriteModal: React$AbstractComponent<
  Props,
  mixed
> = React.memo<Props>(FavoriteModal);

export default MemoizedFavoriteModal;

// @flow
import type { Maker } from '../makers/Maker';
import type { Series } from '../series/Series';
import type { FishingMethod } from '../fishing_methods/FishingMethod';

export type TackleRod = {
  series: Series,
  maker: Maker,
  id: number,
  name_en: string,
  release_year: ?number,
  series_release_year: ?number,
  price: ?number,
  is_open_pricing: boolean,
  lowest_price: ?number,
  url: ?string,
  jan_code: ?string,
  height_m: ?number,
  height_ft: ?number,
  height_min: ?number,
  pieces: ?number,
  weight: ?number,
  power: ?string,
  dia_top: ?number,
  dia_bottom: ?number,
  inner_top: ?number,
  outer_top: ?number,
  replace_dia_top1: ?number,
  replace_dia_top2: ?number,
  inner_bottom: ?number,
  outer_bottom: ?number,
  guide_count: ?number,
  guide_name: ?string,
  reel_seat_position_mm: ?number,
  carbon_ratio: ?number,
  taper: ?string,
  grip_length_mm: ?number,
  lure_weight1_min: ?number,
  lure_weight1_max: ?number,
  lure_weight2_min: ?number,
  lure_weight2_max: ?number,
  lure_weight_min_oz: ?string,
  lure_weight_max_oz: ?string,
  casting_weight_min_g: ?number,
  casting_weight_max_g: ?number,
  vertical_weight_min_g: ?number,
  vertical_weight_max_g: ?number,
  egi_weight_min_go: ?number,
  egi_weight_max_go: ?number,
  sutte_weight_min_go: ?number,
  sutte_weight_max_go: ?number,
  sutte_weight_min_g: ?number,
  sutte_weight_max_g: ?number,
  tenya_weight_min_go: ?number,
  tenya_weight_max_go: ?number,
  lead_weight_min_go: ?number,
  lead_weight_standard_go: ?number,
  lead_weight_max_go: ?number,
  lead_weight_min_g: ?number,
  lead_weight_max_g: ?number,
  line_min: ?number,
  line_max: ?number,
  line_pe_min: ?number,
  line_pe_max: ?number,
  line_nylon_min_go: ?number,
  line_nylon_max_go: ?number,
  line_nylon_min_lb: ?number,
  line_nylon_max_lb: ?number,
  line_fluoro_min_lb: ?number,
  line_fluoro_max_lb: ?number,
  leader_min_go: ?number,
  leader_max_go: ?number,
  wire_min_number: ?number,
  wire_max_number: ?number,
  fly_min_number: ?number,
  fly_max_number: ?number,
  multi_cm: ?string,
  remarks: ?string,
  fishing_methods: Array<FishingMethod>,
  sort_order: number,
};

export const TACKLE_ROD_COLUMN_DEFINITIONS: Array<{
  key: string,
  label: string,
}> = [
  { key: 'name_en', label: 'モデル名\n(番手)' },
  { key: 'lowest_price', label: '最安値' },
  { key: 'series_release_year', label: 'シリーズ\n発売年度' },
  { key: 'weight', label: '自重(g)' },
  { key: 'height_m', label: '全長(m)' },
  { key: 'height_ft', label: '全長(ft)' },
  { key: 'height_min', label: '仕舞(cm)' },
  { key: 'pieces', label: '継数(本)' },
  { key: 'power', label: 'パワー' },
  { key: 'dia_top', label: '先径(mm)' },
  { key: 'dia_bottom', label: '元径(mm)' },
  { key: 'inner_top', label: '先内径(mm)' },
  { key: 'outer_top', label: '先外径(mm)' },
  { key: 'replace_dia_top1', label: '替穂先径1\n(mm)' },
  { key: 'replace_dia_top2', label: '替穂先径2\n(mm)' },
  { key: 'inner_bottom', label: '元内径\n(mm)' },
  { key: 'outer_bottom', label: '元外径\n(mm)' },
  { key: 'guide_count', label: 'ガイド数\n(個)' },
  { key: 'guide_name', label: 'ガイドの種類\n(名称)' },
  { key: 'reel_seat_position_mm', label: 'リールシート位置\n(mm)' },
  { key: 'carbon_ratio', label: 'カーボン含有率\n(%)' },
  { key: 'taper', label: 'テーパー' },
  { key: 'grip_length_mm', label: 'グリップ長\n(mm)' },
  { key: 'lure_weight1_min', label: '最小適合\nルアーウェイト(g)' },
  { key: 'lure_weight1_max', label: '最大適合\nルアーウェイト(g)' },
  { key: 'lure_weight2_min', label: '最小適合\nルアーウェイト2(g)' },
  { key: 'lure_weight2_max', label: '最大適合\nルアーウェイト2(g)' },
  { key: 'lure_weight_min_oz', label: '最小適合\nルアーウェイト(oz)' },
  { key: 'lure_weight_max_oz', label: '最大適合\nルアーウェイト(oz)' },
  { key: 'casting_weight_min_g', label: '最小ルアー重量\nキャスティング(g)' },
  { key: 'casting_weight_max_g', label: '最大ルアー重量\nキャスティング(g)' },
  { key: 'vertical_weight_min_g', label: '最小ルアー重量\nバーチカル(g)' },
  { key: 'vertical_weight_max_g', label: '最大ルアー重量\nバーチカル(g)' },
  { key: 'egi_weight_min_go', label: '最小\nエギサイズ(号)' },
  { key: 'egi_weight_max_go', label: '最大\nエギサイズ(号)' },
  { key: 'sutte_weight_min_go', label: '最小\nスッテサイズ(号)' },
  { key: 'sutte_weight_max_go', label: '最大\nスッテサイズ(号)' },
  { key: 'sutte_weight_min_g', label: '最小\nスッテサイズ(g)' },
  { key: 'sutte_weight_max_g', label: '最大\nスッテサイズ(g)' },
  { key: 'tenya_weight_min_go', label: '最小テンヤ\nサイズ(号)' },
  { key: 'tenya_weight_max_go', label: '最大テンヤ\nサイズ(号)' },
  { key: 'lead_weight_min_go', label: '最小\n鉛負荷(号)' },
  { key: 'lead_weight_standard_go', label: '標準\n鉛負荷(号)' },
  { key: 'lead_weight_max_go', label: '最大\n鉛負荷(号)' },
  { key: 'lead_weight_min_g', label: '最小\n鉛負荷(g)' },
  { key: 'lead_weight_max_g', label: '最大\n鉛負荷(g)' },
  { key: 'line_min', label: '最小\nlbライン(lb)' },
  { key: 'line_max', label: '最大\nlbライン(lb)' },
  { key: 'line_pe_min', label: '最小\n号ライン(号)' },
  { key: 'line_pe_max', label: '最大\n号ライン(号)' },
  { key: 'line_nylon_min_go', label: '最小ナイロン\nライン(号)' },
  { key: 'line_nylon_max_go', label: '最大ナイロン\nライン(号)' },
  { key: 'line_nylon_min_lb', label: '最小ナイロン\nライン(lb)' },
  { key: 'line_nylon_max_lb', label: '最大ナイロン\nライン(lb)' },
  { key: 'line_fluoro_min_lb', label: '最小フロロ\nライン(lb)' },
  { key: 'line_fluoro_max_lb', label: '最大フロロ\nライン(lb)' },
  { key: 'leader_min_go', label: '最小\nハリス(号)' },
  { key: 'leader_max_go', label: '最大\nハリス(号)' },
  { key: 'wire_min_number', label: '最小ハリス\nワイヤー(番)' },
  { key: 'wire_max_number', label: '最大ハリス\nワイヤー(番)' },
  { key: 'fly_min_number', label: '最小フライ\nライン(番)' },
  { key: 'fly_max_number', label: '最大フライ\nライン(番)' },
  { key: 'multi_cm', label: 'マルチ' },
  { key: 'price', label: '希望小売価格' },
];

// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import { useSearchParamsContext } from '../SearchParamsProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faAngleUp,
  faAngleDown,
  faCheck,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

type Props = {
  onPressBack: () => void,
  onCloseModal: () => void,
};

const HeightModal = ({ onPressBack, onCloseModal }: Props): Node => {
  const {
    selectedRodHeightM,
    selectedRodHeightFt,
    setSelectedRodHeightM,
    setSelectedRodHeightFt,
  } = useSearchParamsContext();
  const [heightM, setHeightM] = useState<{
    min: ?number,
    max: ?number,
  }>({
    min: selectedRodHeightM.min,
    max: selectedRodHeightM.max,
  });
  const [heightFt, setHeightFt] = useState<{
    min: ?number,
    max: ?number,
  }>({
    min: selectedRodHeightFt.min,
    max: selectedRodHeightFt.max,
  });
  const [visibleHeightM, setVisibleHeightM] = useState(false);
  const [visibleHeightFt, setVisibleHeightFt] = useState(false);

  const onPressClear = () => {
    setHeightM({ min: undefined, max: undefined });
    setHeightFt({ min: undefined, max: undefined });
  };

  const onSubmit = () => {
    setSelectedRodHeightM(heightM);
    setSelectedRodHeightFt(heightFt);
    onPressBack();
  };

  const onPressHeightM = () => {
    setVisibleHeightM(!visibleHeightM);
  };

  const onPressHeightFt = () => {
    setVisibleHeightFt(!visibleHeightFt);
  };

  return (
    <div data-scope-path="tacklebox/common/modal">
      <div className={`modal modal-active`}>
        <div className="modal-header">
          <div onClick={onPressBack} className="modal-back">
            <FontAwesomeIcon
              icon={faAngleLeft}
              size="xs"
              className="angle_left_icon"
            />
            戻る
          </div>
          <div>ロッド-全長</div>
          <FontAwesomeIcon
            icon={faXmark}
            size="xs"
            className="close_icon"
            onClick={() => onCloseModal()}
          />
        </div>
        <div className="modal-body">
          <div className="display_flex flex_wrap align_center fz_sub fc_sub">
            <span onClick={onPressClear} className="fc_sub">
              <i className="fa fa-times fz_sub-headline" aria-hidden="true"></i>
              条件をクリア
            </span>
          </div>
        </div>
        <div className="modal-scroll-body">
          <div className="display_flex flex_wrap inner">
            <div className="checkbox" onClick={onPressHeightM}>
              <div className="list_label">全長(m)から探す</div>
              <div className="selected_values">
                <FontAwesomeIcon
                  icon={visibleHeightM ? faAngleUp : faAngleDown}
                  size="xs"
                  className="right_icon"
                />
              </div>
            </div>
            {visibleHeightM && (
              <div className="more-filter-list">
                <div className="pure-u-1">
                  <input
                    value={heightM.min}
                    placeholder="m"
                    onChange={e =>
                      setHeightM({
                        ...heightM,
                        min: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="p_3">〜</div>
                <div className="pure-u-1">
                  <input
                    value={heightM.max}
                    placeholder="m"
                    onChange={e =>
                      setHeightM({
                        ...heightM,
                        max: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            )}
            <div className="checkbox" onClick={onPressHeightFt}>
              <div className="list_label">全長(ft)から探す</div>
              <div className="selected_values">
                <FontAwesomeIcon
                  icon={visibleHeightFt ? faAngleUp : faAngleDown}
                  size="xs"
                  className="right_icon"
                />
              </div>
            </div>
            {visibleHeightFt && (
              <div className="more-filter-list">
                <div className="pure-u-1">
                  <input
                    value={heightFt.min}
                    type="number"
                    placeholder="ft"
                    onChange={e =>
                      setHeightFt({
                        ...heightFt,
                        min: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="p_3">〜</div>
                <div className="pure-u-1">
                  <input
                    value={heightFt.max}
                    type="number"
                    placeholder="ft"
                    onChange={e =>
                      setHeightFt({
                        ...heightFt,
                        max: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            onClick={onSubmit}
            className="btn_primary submit_button"
          >
            <FontAwesomeIcon icon={faCheck} size="xs" />
            決定
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeightModal;

// @flow
import type { Node } from 'react';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import type { TackleboxTopSlider } from '../../models/TackleboxTopSlider';

type Props = { images: TackleboxTopSlider[] };

function SlickCarouselRoot(props: Props): Node {
  return <SlickCarousel {...props} />;
}

function SlickCarousel(props: Props) {
  const settings = {
    autoplay: true,
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    centerMode: true,
    centerPadding: '50px',
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    variableWidth: true,
    useTransform: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          variableWidth: false,
          centerPadding: '10%',
        },
      },
    ],
  };

  return (
    <div data-scope-path="tacklebox/top/slick_carousel">
      <Slider {...settings}>
        {props.images.map((image, index) => (
          <a
            className="ga4_click4"
            href={image.url}
            key={index}
            style={{ width: 636, height: 318 }}
            target={image.target ? '_blank' : '_self'}
            rel="noreferrer"
          >
            <div className="slick-image-wrap">
              <div className="slick-image" data-index={index}>
                <figure>
                  <img
                    src={image.image_url}
                    alt={image.caption.replace(/(\n)/gm, '')}
                  />
                  {image.caption && (
                    <div className="slider-text">
                      <span>{image.caption}</span>
                    </div>
                  )}
                </figure>
              </div>
            </div>
          </a>
        ))}
      </Slider>
    </div>
  );
}

export default SlickCarouselRoot;

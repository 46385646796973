// @flow
import React from 'react';

import type { TackleRod } from '../../models/tackle_rods/TackleRod';
import { TACKLE_ROD_COLUMN_DEFINITIONS } from '../../models/tackle_rods/TackleRod';

type Props = {
  tackleRods: Array<TackleRod>,
  displayColumns: Array<string>,
};

type Columns = {
  key: string,
  label: string,
};

function RodTableRoot(props: Props): React$Node {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <RodTable {...props} />;
}

function RodTable({ tackleRods, displayColumns }: Props) {
  const columns: Array<Columns> = TACKLE_ROD_COLUMN_DEFINITIONS.reduce(
    (acc, d) => {
      if (displayColumns.includes(d.key)) acc.push(d);
      return acc;
    },
    []
  );

  return (
    <>
      <div data-scope-path="tacklebox/series/models_table">
        <table>
          <thead>
            <tr className="">
              {columns.map(c => {
                return <th key={c.key}>{c.label}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {tackleRods.map(r => {
              return (
                <tr key={r.id}>
                  {columns.map(c => {
                    if (c.key === 'name_en') {
                      return (
                        <td key={c.key}>
                          <a href={`/rods/${r.id}`}>{r[c.key]}</a>
                        </td>
                      );
                    } else if (c.key === 'lowest_price') {
                      let lowestPriceCell = null;
                      if (r[c.key]) {
                        lowestPriceCell = r[c.key].toLocaleString() + '円';
                      }
                      if (lowestPriceCell) {
                        return (
                          <td key={c.key}>
                            <a href={`/rods/${r.id}`}>{lowestPriceCell}</a>
                          </td>
                        );
                      } else {
                        return <td key={c.key}>-</td>;
                      }
                    } else {
                      return <td key={c.key}>{r[c.key]}</td>;
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default RodTableRoot;

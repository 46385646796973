// @flow
import React from 'react';
import type { Node } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

type Props = {
  visible: boolean,
  onClose: boolean => void,
};

const SpecModal = ({ visible, onClose }: Props): Node => {
  return (
    <div data-scope-path="tacklebox/common/modal">
      <div
        className={`back-drop ${visible ? 'back-drop-active' : ''}`}
        onClick={onClose}
      />
      <div className={`modal ${visible ? 'modal-active' : ''}`}>
        <div className="modal-header">
          <FontAwesomeIcon
            icon={faAngleDown}
            size="xs"
            className="drop_down_icon"
          />
          スペック詳細について
        </div>
        <div className="modal-body">
          <p>
            記載のスペックはメーカーHPより引用した際に「約」などの記載を省いた参考値です。
          </p>
        </div>
        <div className="modal-footer">
          <a onClick={onClose} className="btn_primary">
            閉じる
          </a>
        </div>
      </div>
    </div>
  );
};

export default SpecModal;

// @flow
import React from 'react';
import type { Node } from 'react';
import type { SeriesWithModels } from '../../models/series/SeriesWithModels';
import SeriesCard from './SeriesCard';
import bannerSpImage from '../../assets/images/views/search/bannerSp.webp';

type Props = {
  series: Array<SeriesWithModels>,
  searchBySeabass: boolean,
};

function SeriesCardList({ series, searchBySeabass }: Props): Node {
  return (
    <>
      <div
        className="n_m_3"
        data-scope-path="tacklebox/search/series_card_list"
      >
        {series.map((item, index) => (
          <React.Fragment key={item.id}>
            {searchBySeabass && (index === 3 || index === 11) ? (
              <a
                href="https://anglers.thebase.in/categories/5560974"
                target="_blank"
                className="seabass_lure_banner_top_sp"
                rel="noopener noreferrer"
              >
                <img
                  src={bannerSpImage}
                  alt="ルアーバナー"
                  className="pure-u-1 pure-u-md-1-2 pure-u-xl-1-3 p_3"
                  style={{ borderRadius: '16px' }}
                />
              </a>
            ) : null}
            <SeriesCard series={item} />
          </React.Fragment>
        ))}
      </div>
    </>
  );
}

export default SeriesCardList;

// @flow
import React from 'react';
import type { SearchModel } from '../../models/products/SearchModel';
import Stars from '../reviews/Stars';

type Props = {
  model: SearchModel,
};

function ModelCard({ model }: Props): React$Node {
  return (
    <section data-scope-path="tacklebox/search/model_card">
      <a href={model.type_model_path} className="card_cover">
        <img src={model.image_url} alt="" className="" />
        <div className="cover_details">
          <h1>
            {model.maker && <div className="maker">{model.maker.name_en}</div>}
            <div className="series">
              {model.series.name_jp
                ? model.series.name_jp
                : model.series.name_en}
            </div>
            <div className="model">
              {model.name_jp ? model.name_jp : model.name_en}
            </div>
            {model.series.age && (
              <div className="age">{model.series.age}年モデル</div>
            )}
          </h1>
          <Stars score={model.total_score} starSize="md" />
          <div className="lowest_price">
            最安値
            <div className="price">
              {model.lowest_price ? model.lowest_price.toLocaleString() : '- '}
              円
            </div>
          </div>
        </div>
      </a>
    </section>
  );
}

export default ModelCard;

// @flow
import React, { useState } from 'react';
import type { TackleLine } from '../../models/tackle_lines/TackleLine';
import { TACKLE_LINE_COLUMN_DEFINITIONS } from '../../models/tackle_lines/TackleLine';
import FavoriteModal from './FavoriteModal';
import LineFavoriteClient from '../../models/favorites/LineFavoriteClient';

type Props = {
  tackleLines: Array<TackleLine>,
  token: string,
};

type Columns = {
  key: string,
  label: string,
};

function LineTableRoot(props: Props) {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <LineTable {...props} />;
}

function LineTable({ tackleLines, token }: Props) {
  const [visible, setVisible] = useState<boolean>(false);
  const [lineId, setLineId] = useState<number>(0);
  const [maskedLines, setMaskedLines] = useState<Array<TackleLine>>(
    tackleLines
  );

  const onOpen = (id: number) => {
    setLineId(id);
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onRemove = (id: number) => {
    const lineFavoriteClient = new LineFavoriteClient();
    lineFavoriteClient
      .removeFavorite(id, token)
      .then(() => {
        setMaskedLines(maskedLines.filter(line => line.id != id));
        setVisible(false);
      })
      .catch(error => console.error(error));
  };

  const displayKeys = maskedLines.reduce((acc, line) => {
    const entries = Object.keys(line).filter(key => {
      return line[key] != null && line[key] !== '';
    });
    return Array.from(new Set([...entries, ...acc]));
  }, []);

  const displayDefinitions: Array<Columns> = TACKLE_LINE_COLUMN_DEFINITIONS.filter(
    definition => {
      return displayKeys.includes(definition.key);
    }
  );

  const lineNameColumn = (line: TackleLine) => {
    return (
      <div className="list_control">
        <a href={`/lines/${line.id}`} className="name">
          {line.series.name_jp}
        </a>
        <a
          className="remove_list"
          onClick={() => {
            onOpen(line.id);
          }}
        >
          <i className="las la-times"></i>
        </a>
      </div>
    );
  };

  const displayTableHead = (definitions: Array<Columns>) => {
    return (
      <tr>
        <th>モデル名</th>
        {definitions.map(definition => {
          return <th key={definition.key}>{definition.label}</th>;
        })}
      </tr>
    );
  };

  const displayTableBody = (lines: Array<TackleLine>) => {
    return (
      <>
        {lines.map(line => {
          return (
            <tr key={line.id}>
              <td>{lineNameColumn(line)}</td>
              {displayDefinitions.map(definition => {
                return <td key={definition.key}>{line[definition.key]}</td>;
              })}
            </tr>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div data-scope-path="tacklebox/favorites/comparison_table">
        {(() => {
          if (maskedLines.length == 0) {
            return <p>お気に入りのラインはありません。</p>;
          } else {
            return (
              <table>
                <thead>{displayTableHead(displayDefinitions)}</thead>
                <tbody>{displayTableBody(maskedLines)}</tbody>
              </table>
            );
          }
        })()}
      </div>
      <FavoriteModal
        visible={visible}
        onClose={onClose}
        modelId={lineId}
        onRemove={onRemove}
      />
    </>
  );
}

export default LineTableRoot;

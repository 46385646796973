// @flow
import type { Node } from 'react';
import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PhotoSwipe from 'photoswipe';
import 'photoswipe/src/photoswipe.css';

type Image = {
  src: string,
  slick_src: string,
  width: number,
  height: number,
};

type Props = {
  images: Array<Image>,
  isEmpty: boolean,
  productType: string,
};

function SlickCarouselRoot(props: Props): Node {
  return <SlickCarousel {...props} />;
}

function SlickCarousel({ images, isEmpty, productType }: Props) {
  // https://github.com/akiran/react-slick/issues/1195#issuecomment-390383615
  /* eslint-disable */
  // $FlowFixMe
  const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
    <span {...props}>{children}</span>
  );
  /* eslint-enable */
  const isDragging = useRef<boolean>(false);
  const [currentSlideNumber, setCurrentSlideNumber] = useState<number>(1);
  const settings = {
    arrows: true,
    nextArrow: (
      <SlickButtonFix>
        <i className="fa fa-angle-right" />
      </SlickButtonFix>
    ),
    prevArrow: (
      <SlickButtonFix>
        <i className="fa fa-angle-left" />
      </SlickButtonFix>
    ),
    onSwipe: () => {
      isDragging.current = true;
      setTimeout(() => {
        isDragging.current = false;
      }, 100);
    },
    dots: false,
    afterChange: currentSlide => {
      setCurrentSlideNumber(currentSlide + 1);
    },
  };

  const rescaledImages = (currentWidth: number) => {
    return images.map(image => {
      const ratio: number = image.width / currentWidth;
      const rescaledHeight: number = image.height / ratio;
      return { src: image.src, width: currentWidth, height: rescaledHeight };
    });
  };

  const onClick = (element: Object) => {
    if (isDragging.current) return;
    const target: HTMLImageElement = element.target;
    const currentWidth: number = target.width;
    const clickedImage: number = Number(target.getAttribute('data-index'));
    const pswp = new PhotoSwipe({
      dataSource: rescaledImages(currentWidth),
      index: clickedImage,
      counter: false,
      initialZoomLevel: 1,
      secondaryZoomLevel: 2,
    });
    pswp.init();
  };

  return (
    <div data-scope-path="tacklebox/models/slick_carousel">
      {isEmpty && productType !== 'ライン' && (
        <div className="image-note">
          写真は同じシリーズの異なる製品のものです
        </div>
      )}
      {productType === 'ライン' && (
        <div className="image-note">写真は全ラインナップで共通です</div>
      )}
      <Slider {...settings}>
        {images.map((image, index) => (
          <div className="slick-image-wrap" key={index}>
            <img
              className="slick-image"
              src={image.slick_src}
              onClick={onClick}
              data-index={index}
            />
          </div>
        ))}
      </Slider>
      <div className="slide-number">
        <span>
          {currentSlideNumber}/{images.length}
        </span>
      </div>
    </div>
  );
}

export default SlickCarouselRoot;

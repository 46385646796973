// @flow
import React from 'react';
import type { Node } from 'react';
import type { PrototypeTackleLure } from '../../models/prototype_tackle_lures/PrototypeTackleLure.js';
import PrototypeLureCard from './PrototypeLureCard';

type Props = {
  lures: Array<PrototypeTackleLure>,
};

function PrototypeLureCardList({ lures }: Props): Node {
  return (
    <>
      <div
        className="container"
        data-scope-path="tacklebox/search/series_card_list"
      >
        {lures.map(item => (
          <PrototypeLureCard key={item.id} lure={item} />
        ))}
      </div>
    </>
  );
}

export default PrototypeLureCardList;

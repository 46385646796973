// @flow
import React, { useState, useEffect } from 'react';
import type { TackleRod } from '../../models/tackle_rods/TackleRod';
import { breakPoint } from '../../lib/Mixins';

type Props = {
  currentRod: TackleRod,
  rods: Array<TackleRod>,
};

function LikelyRodButtons({ currentRod, rods }: Props): React$Node {
  // modelsに含まれる要素のsort_orderの昇順に並び替える
  const orderedRods = rods.sort((a, b) => a.sort_order - b.sort_order);
  const [isAccordionOpen, setIsAccordionOpen] = useState(
    window.innerWidth > breakPoint() - 1
  );

  useEffect(() => {
    const handleResize = () => {
      setIsAccordionOpen(window.innerWidth > breakPoint() - 1);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const orderedModelList = (
    <div className="list-container">
      {(isAccordionOpen
        ? orderedRods
        : orderedRods.filter((_, index) => index < 4)
      ).map(rod => (
        <a
          href={`/rods/${rod.id}`}
          key={rod.id}
          className={`ga4_select_model list-item ${
            rod.id === currentRod.id ? 'active' : ''
          }`}
        >
          <div className="active-text">{rod.name_en}</div>
          <div className="list-item-for-sp">
            <div className="price">
              {rod.lowest_price ? rod.lowest_price.toLocaleString() : '- '}円
            </div>
            <div className="spec">
              {rod.weight && <>自重{rod.weight}g</>}
              {rod.height_ft ? (
                <> 全長{rod.height_ft}ft</>
              ) : (
                <>{rod.height_m && <> 全長{rod.height_m}m</>}</>
              )}
            </div>
          </div>
        </a>
      ))}
    </div>
  );

  return (
    <>
      <h2 className="title">モデルを選択</h2>
      {orderedModelList}
      <div className="accordion-button-container">
        <button
          className={`accordion-button ${isAccordionOpen ? 'open' : ''}`}
          onClick={() => setIsAccordionOpen(!isAccordionOpen)}
        >
          {isAccordionOpen ? '閉じる' : 'すべて表示'}
        </button>
      </div>
    </>
  );
}

export default LikelyRodButtons;

// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import type { TackleReel } from '../../models/tackle_reels/TackleReel';
import { TACKLE_REEL_COLUMN_DEFINITIONS } from '../../models/tackle_reels/TackleReel';
import SpecModal from './SpecModal';

type Props = {
  tackleReel: TackleReel,
};

function ReelSpeckCarouselRoot(props: Props): Node {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <ReelSpeckCarousel {...props} />;
}

function ReelSpeckCarousel({ tackleReel }: Props) {
  const [active, setActive] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  const onScroll = () => {
    setActive(true);
  };

  const onOpen = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <section>
        <div className="display_flex justify_between align_center">
          <h2 className="headline_sub mb_3">
            スペック詳細
            <i className="fa fa-question-circle-o" onClick={onOpen}></i>
          </h2>
        </div>

        <div className="mt_3">
          <div data-scope-path="tacklebox/models/speck_carousel_component">
            <div className="models_speck_carousel_container">
              <div className="models_speck_carousel" onScroll={onScroll}>
                {TACKLE_REEL_COLUMN_DEFINITIONS.map(column => {
                  if (column.key === 'lowest_price') return;
                  return (
                    tackleReel[column.key] && (
                      <div className="carousel_item" key={column.key}>
                        <div className="models_speck_carousel_item_header">
                          {column.label}
                        </div>
                        {tackleReel[column.key]}
                      </div>
                    )
                  );
                })}
              </div>

              <div
                className={`models_speck_carousel_guide ${
                  active ? 'active' : ''
                }`}
              >
                <i className="fa fa-chevron-right" aria-hidden="true"></i>
              </div>
            </div>
            {tackleReel.remarks && (
              <div className="remark">
                <div className="remark_label">備考</div>
                <div className="remark_content">{tackleReel.remarks}</div>
              </div>
            )}
          </div>
        </div>
      </section>

      <SpecModal visible={visible} onClose={onClose} />
    </>
  );
}

export default ReelSpeckCarouselRoot;

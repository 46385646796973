// @flow
import React, { useState } from 'react';
import type { Node } from 'react';

type Props = {
  productId: number,
  voteType: number, // 0: 一軍アイテム, 1: 買いたいけど売ってない
  votesCast: number,
  isVoted: boolean,
  isSignedIn: boolean,
  token: string,
};

function VoteButtonRoot(props: Props): Node {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <VoteButton {...props} />;
}

function VoteButton({
  productId,
  voteType,
  votesCast,
  isVoted,
  isSignedIn,
  token,
}: Props) {
  const [voted, setVoted] = useState(isVoted);
  const [count, setCount] = useState(votesCast);

  const onClick = () => {
    // TODO: 仕様が決まったら修正する
    console.log(`productId: ${productId}, token: ${token}`);
    if (voted) {
      console.log('投票済');
      setVoted(false);
      setCount(count - 1);
    } else {
      console.log('未投票');
      setVoted(true);
      setCount(count + 1);
    }
  };

  const inputId =
    voteType === 0 ? 'vote-standard-item' : 'vote-difficult-obtainal';
  const label = voteType === 0 ? '一軍アイテム' : '買いたいけど売ってない';

  return (
    <div className="models_main_visual_favorite">
      {isSignedIn ? (
        <div className="checkbox_group">
          <input
            type="checkbox"
            id={inputId}
            onClick={onClick}
            defaultChecked={voted}
          />
          <label htmlFor={inputId} className="display_flex justify_between">
            {label}
            <span className="fc_link">{count}</span>
          </label>
        </div>
      ) : (
        <div className="checkbox_group">
          <a href="/users/sign_in">
            <label className="display_flex justify_between">
              {label}
              <span className="fc_link">{count}</span>
            </label>
          </a>
        </div>
      )}
    </div>
  );
}

export default VoteButtonRoot;

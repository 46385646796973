// @flow
import React from 'react';

import type { Advertisement } from '../../models/advertisements/Advertisement';

import ShopLink from './ShopLink';

type Props = {
  advertisements: Array<Advertisement>,
  base_price: ?number,
};

function ShopLinkList({ advertisements, base_price }: Props): React$Node {
  const amazon: Advertisement =
    advertisements.find(
      advertisement => advertisement.sponsor.name === 'Amazon'
    ) || {};
  const yahoo =
    advertisements.find(
      advertisement => advertisement.sponsor.name === 'Yahoo'
    ) || {};
  const rakuen =
    advertisements.find(
      advertisement => advertisement.sponsor.name === 'Rakuten'
    ) || {};
  const displayAdvertisements: Array<Advertisement> = [amazon, yahoo, rakuen];

  return (
    <div
      className="display_flex"
      data-scope-path="react_components/tacklebox/models/shop_link_list_component"
    >
      {displayAdvertisements.map((value, index) => {
        return (
          <section className="shop_link_container" key={index}>
            <ShopLink
              advertisement={value}
              index={index + 1}
              base_price={base_price}
            />
          </section>
        );
      })}
    </div>
  );
}

export default ShopLinkList;

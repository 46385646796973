// @flow
import React from 'react';

import type { TackleLine } from '../../models/tackle_lines/TackleLine';
import { TACKLE_LINE_COLUMN_DEFINITIONS } from '../../models/tackle_lines/TackleLine';

type Props = {
  tackleLines: Array<TackleLine>,
  displayColumns: Array<string>,
};

type Columns = {
  key: string,
  label: string,
};

function LineTableRoot(props: Props) {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <LineTable {...props} />;
}

function LineTable({ tackleLines, displayColumns }: Props) {
  const columns: Array<Columns> = TACKLE_LINE_COLUMN_DEFINITIONS.reduce(
    (acc, d) => {
      if (displayColumns.includes(d.key)) acc.push(d);
      return acc;
    },
    []
  );

  return (
    <>
      <div data-scope-path="tacklebox/series/models_table">
        <table>
          <thead>
            <tr className="">
              {columns.map(c => {
                return <th key={c.key}>{c.label}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {tackleLines.map(r => {
              return (
                <tr key={r.id}>
                  {columns.map(c => {
                    if (c.key == 'name_en') {
                      return (
                        <td key={c.key}>
                          <a href={`/lines/${r.id}`}>{r[c.key]}</a>
                        </td>
                      );
                    } else {
                      return <td key={c.key}>{r[c.key]}</td>;
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default LineTableRoot;

// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import { useSearchParamsContext } from '../SearchParamsProvider';
import { TACKLE_LINE_MATERIAL_COLUMN_DEFINITIONS } from '../../../models/tackle_lines/TackleLine';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faCheck,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

type Props = {
  onPressBack: () => void,
  onCloseModal: () => void,
};

const LineCategoryModal = ({ onPressBack, onCloseModal }: Props): Node => {
  const {
    selectedLineCategories,
    setSelectedLineCategories,
  } = useSearchParamsContext();
  const [lineCategories, setLineCategories] = useState(selectedLineCategories);

  const onPressClear = () => {
    setLineCategories([]);
  };

  const onSubmit = () => {
    setSelectedLineCategories(lineCategories);
    onPressBack();
  };

  const updateLineCategory = ({ key }) => {
    if (lineCategories.find(categoryId => categoryId === key)) {
      const list = lineCategories.filter(categoryId => categoryId !== key);
      setLineCategories(list);
    } else {
      setLineCategories(lineCategories.concat(key));
    }
  };

  return (
    <div data-scope-path="tacklebox/common/modal">
      <div className={`modal modal-active`}>
        <div className="modal-header">
          <div onClick={onPressBack} className="modal-back">
            <FontAwesomeIcon
              icon={faAngleLeft}
              size="xs"
              className="angle_left_icon"
            />
            戻る
          </div>
          <div>ライン-素材を選択</div>
          <FontAwesomeIcon
            icon={faXmark}
            size="xs"
            className="close_icon"
            onClick={() => onCloseModal()}
          />
        </div>
        <div className="modal-body">
          <div className="display_flex flex_wrap align_center fz_sub fc_sub">
            <div className="mr_3">選択中</div>
            {lineCategories.map(lineCategory => (
              <div className="mr_3" key={lineCategory}>
                {
                  TACKLE_LINE_MATERIAL_COLUMN_DEFINITIONS.find(
                    material => material.key === lineCategory
                  )?.label
                }
              </div>
            ))}
            <span onClick={onPressClear} className="fc_sub">
              <i className="fa fa-times fz_sub-headline" aria-hidden="true"></i>
              条件をクリア
            </span>
          </div>
        </div>
        <div className="modal-scroll-body">
          {TACKLE_LINE_MATERIAL_COLUMN_DEFINITIONS.map(lineCategory => (
            <div
              className={`checkbox line_category ${
                lineCategories.find(
                  lineCategories => lineCategories === lineCategory.key
                )
                  ? 'active'
                  : ''
              }`}
              key={lineCategory.key}
              onClick={() => updateLineCategory(lineCategory)}
            >
              <input type="checkbox" />
              <label>
                <div className="name">{lineCategory.label}</div>
                <FontAwesomeIcon
                  icon={faCheck}
                  size="xs"
                  className="check_icon"
                />
              </label>
            </div>
          ))}
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            onClick={onSubmit}
            className="btn_primary submit_button"
          >
            <FontAwesomeIcon icon={faCheck} size="xs" />
            決定
          </button>
        </div>
      </div>
    </div>
  );
};

export default LineCategoryModal;

// @flow
import React, { useState, useEffect } from 'react';
import type { TackleReel } from '../../models/tackle_reels/TackleReel';
import { breakPoint } from '../../lib/Mixins';

type Props = {
  currentReel: TackleReel,
  reels: Array<TackleReel>,
};

function LikelyReelButtons({ currentReel, reels }: Props): React$Node {
  // modelsに含まれる要素のsort_orderの昇順に並び替える
  const orderedReels = reels.sort((a, b) => a.sort_order - b.sort_order);
  const [isAccordionOpen, setIsAccordionOpen] = useState(
    window.innerWidth > breakPoint() - 1
  );

  useEffect(() => {
    // 初期値を現在の横幅で設定
    let lastWidth = window.innerWidth;

    const handleResize = () => {
      // 現在の横幅を取得
      const currentWidth = window.innerWidth;
      // 前回の横幅と現在の横幅が異なる場合のみ処理
      if (lastWidth !== currentWidth) {
        setIsAccordionOpen(currentWidth > breakPoint() - 1);
        // lastWidthを更新
        lastWidth = currentWidth;
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const orderedModelList = (
    <div className="list-container">
      {(isAccordionOpen
        ? orderedReels
        : orderedReels.filter((_, index) => index < 4)
      ).map(reel => (
        <a
          href={`/reels/${reel.id}`}
          key={reel.id}
          className={`ga4_select_model list-item ${
            reel.id === currentReel.id ? 'active' : ''
          }`}
        >
          <div className="active-text">{reel.name_en}</div>
          <div className="list-item-for-sp">
            <div className="price">
              {reel.lowest_price ? reel.lowest_price.toLocaleString() : '- '}円
            </div>
            <div className="spec">
              {reel.weight && <>自重{reel.weight}g</>}
              {reel.winding_length && <> 巻取り{reel.winding_length}/回転</>}
            </div>
          </div>
        </a>
      ))}
    </div>
  );

  return (
    <>
      <h2 className="title">モデルを選択</h2>
      {orderedModelList}
      <div className="accordion-button-container">
        <button
          className={`accordion-button ${isAccordionOpen ? 'open' : ''}`}
          onClick={() => setIsAccordionOpen(!isAccordionOpen)}
        >
          {isAccordionOpen ? '閉じる' : 'すべて表示'}
        </button>
      </div>
    </>
  );
}

export default LikelyReelButtons;

// @flow
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons';

type Props = {
  url: string,
  customClassName: string | null,
};

function ShareButtonRoot(props: Props) {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <ShareButton {...props} />;
}

function ShareButton({ url, customClassName }: Props) {
  const onShare = () => {
    if (window.navigator) {
      window.navigator.share({
        title: document.title,
        text: document.title,
        url: url,
      });
    }
  };

  return (
    <div
      className={`${customClassName ? customClassName : 'model'}_share_button`}
      data-scope-path="react_components/tacklebox/commons/share_button_component"
      onClick={onShare}
    >
      <FontAwesomeIcon
        icon={faArrowUpFromBracket}
        size="xs"
        className="drop_down_icon"
      />
    </div>
  );
}

export default ShareButtonRoot;

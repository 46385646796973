// @flow
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import type { Maker } from '../../models/makers/Maker';
import type { FishingMethod } from '../../models/fishing_methods/FishingMethod';
import type { Fish } from '../../models/fishes/Fish';
import type { SearchParamsContextType } from './SearchParamsContextType';
import type { TackleType } from './TackleType';

type Props = {
  makers: Array<Maker>,
  fishes: Array<Fish>,
  fishingMethods: Array<FishingMethod>,
  initialType: TackleType,
  tackleType: TackleType,
  children: React$Node,
};

const SearchParamsContext = createContext<SearchParamsContextType>({});
export const useSearchParamsContext = (): SearchParamsContextType =>
  useContext(SearchParamsContext);

export const SearchParamsProvider = ({
  makers,
  fishes,
  fishingMethods,
  initialType,
  children,
}: Props): React$Node => {
  const [selectedMakers, setSelectedMakers] = useState<Array<Maker>>([]);
  const [selectedFishes, setSelectedFishes] = useState<Array<Fish>>([]);
  const [selectedFishingMethods, setSelectedFishingMethods] = useState<
    Array<FishingMethod>
  >([]);
  const [filteredMakers, setFilteredMakers] = useState<Array<Maker>>([]);
  const [filteredFishes, setFilteredFishes] = useState<Array<Fish>>([]);
  const [filteredFishingMethods, setFilteredFishingMethods] = useState<
    Array<FishingMethod>
  >([]);
  const [selectedMakerNames, setSelectedMakerNames] = useState([]);
  const [selectedFishNames, setSelectedFishNames] = useState([]);
  const [selectedFishingMethodNames, setSelectedFishingMethodNames] = useState(
    []
  );
  const [makerKeywords, setMakerKeywords] = useState('');
  const [fishingMethodKeywords, setFishingMethodKeywords] = useState('');
  const [fishKeywords, setFishKeywords] = useState('');
  const [selectedPriceRange, setSelectedPriceRange] = useState<{
    min: ?number,
    max: ?number,
  }>({
    min: undefined,
    max: undefined,
  });
  const [initialMakers, setInitialMakers] = useState<Array<Maker>>([]);
  const [initialFishingMethods, setInitialFishingMethods] = useState<
    Array<FishingMethod>
  >([]);
  const [initialPriceRange, setInitialPriceRange] = useState<{
    min: ?number,
    max: ?number,
  }>({
    min: undefined,
    max: undefined,
  });
  const [selectedScore, setSelectedScore] = useState<{
    min: ?number,
    max: ?number,
  }>({
    min: undefined,
    max: undefined,
  });
  const [selectedRodHeightM, setSelectedRodHeightM] = useState<{
    min: ?number,
    max: ?number,
  }>({
    min: undefined,
    max: undefined,
  });
  const [selectedRodHeightFt, setSelectedRodHeightFt] = useState<{
    min: ?number,
    max: ?number,
  }>({
    min: undefined,
    max: undefined,
  });
  const [selectedRodPiece, setSelectedRodPiece] = useState<{
    min: ?number,
    max: ?number,
  }>({
    min: undefined,
    max: undefined,
  });
  const [selectedRodHeightMin, setSelectedRodHeightMin] = useState<{
    min: ?number,
    max: ?number,
  }>({
    min: undefined,
    max: undefined,
  });
  const [selectedRodWeight, setSelectedRodWeight] = useState<{
    min: ?number,
    max: ?number,
  }>({
    min: undefined,
    max: undefined,
  });
  const [selectedDragPower, setSelectedDragPower] = useState<{
    min: ?number,
    max: ?number,
  }>({
    min: undefined,
    max: undefined,
  });
  const [selectedLineNylonGo, setSelectedLineNylonGo] = useState(undefined);
  const [
    selectedLineCapacityNylonGo,
    setSelectedLineCapacityNylonGo,
  ] = useState(undefined);
  const [selectedLineNylonLb, setSelectedLineNylonLb] = useState(undefined);
  const [
    selectedLineCapacityNylonLb,
    setSelectedLineCapacityNylonLb,
  ] = useState(undefined);
  const [selectedLineFluorocarbonGo, setSelectedLineFluorocarbonGo] = useState(
    undefined
  );
  const [
    selectedLineCapacityFluorocarbonGo,
    setSelectedLineCapacityFluorocarbonGo,
  ] = useState(undefined);
  const [selectedLineFluorocarbonLb, setSelectedLineFluorocarbonLb] = useState(
    undefined
  );
  const [
    selectedLineCapacityFluorocarbonLb,
    setSelectedLineCapacityFluorocarbonLb,
  ] = useState(undefined);
  const [selectedLinePeGo, setSelectedLinePeGo] = useState(undefined);
  const [selectedLineCapacityPeGo, setSelectedLineCapacityPeGo] = useState(
    undefined
  );
  const [selectedLinePeLb, setSelectedLinePeLb] = useState(undefined);
  const [selectedLineCapacityPeLb, setSelectedLineCapacityPeLb] = useState(
    undefined
  );
  const [selectedLineCategories, setSelectedLineCategories] = useState([]);
  const [selectedLineSizeGo, setSelectedLineSizeGo] = useState<{
    min: ?number,
    max: ?number,
  }>({
    min: undefined,
    max: undefined,
  });
  const [selectedLineSizeLb, setSelectedLineSizeLb] = useState<{
    min: ?number,
    max: ?number,
  }>({
    min: undefined,
    max: undefined,
  });
  const [selectedLineCapacity, setSelectedLineCapacity] = useState<{
    min: ?number,
    max: ?number,
  }>({
    min: undefined,
    max: undefined,
  });
  const [selectedLineColors, setSelectedLineColors] = useState([]);
  const [tackleType, setTackleType] = useState(
    initialType ? initialType : 'lure'
  );
  const formRef = useRef(null);
  const initialTackleType = useRef(initialType ? initialType : 'lure');

  useEffect(() => {
    // モーダルの初期値設定
    setFilteredMakers(makers);
    setFilteredFishingMethods(fishingMethods);
    setFilteredFishes(fishes);

    const params = new URLSearchParams(window.location.search);

    // メーカー検索の初期値を設定
    const makerIdsParams = params.get('maker_ids');
    if (makerIdsParams) {
      filterMakersByIds(makerIdsParams.split(',').map(id => parseInt(id)));
    }

    // 釣り方検索の初期値を設定
    const fishingMethodIds = params.get('fishing_method_ids');
    if (fishingMethodIds) {
      filterFishingMethodsByIds(
        fishingMethodIds.split(',').map(id => parseInt(id))
      );
    }

    // 評価の初期値を設定
    const min_score = params.get('min_score');
    const max_score = params.get('max_score');
    if (min_score || max_score) {
      setSelectedScore({
        min: min_score ? parseInt(min_score) : undefined,
        max: max_score ? parseInt(max_score) : undefined,
      });
    }

    // 価格帯検索の初期値を設定
    const min_price = params.get('min_price');
    const max_price = params.get('max_price');
    if (min_price || max_price) {
      setInitialPriceRange({
        min: min_price ? parseInt(min_price) : undefined,
        max: max_price ? parseInt(max_price) : undefined,
      });
      setSelectedPriceRange({
        min: min_price ? parseInt(min_price) : undefined,
        max: max_price ? parseInt(max_price) : undefined,
      });
    }

    // 高度検索 ロッド全長の初期値を設定
    const min_height_m = params.get('min_height_m');
    const max_height_m = params.get('max_height_m');
    if (min_height_m || max_height_m) {
      setSelectedRodHeightM({
        min: min_height_m ? parseInt(min_height_m) : undefined,
        max: max_height_m ? parseInt(max_height_m) : undefined,
      });
    }
    const min_height_ft = params.get('min_height_ft');
    const max_height_ft = params.get('max_height_ft');
    if (min_height_ft || max_height_ft) {
      setSelectedRodHeightFt({
        min: min_height_ft ? parseInt(min_height_ft) : undefined,
        max: max_height_ft ? parseInt(max_height_ft) : undefined,
      });
    }

    // 高度検索 ロッド総本数の初期値を設定
    const min_piece = params.get('min_piece');
    const max_piece = params.get('max_piece');
    if (min_piece || max_piece) {
      setSelectedRodPiece({
        min: min_piece ? parseInt(min_piece) : undefined,
        max: max_piece ? parseInt(max_piece) : undefined,
      });
    }

    // 高度検索 ロッド仕舞寸法の初期値を設定
    const min_height_min = params.get('min_height_min');
    const max_height_min = params.get('max_height_min');
    if (min_height_min || max_height_min) {
      setSelectedRodHeightMin({
        min: min_height_min ? parseInt(min_height_min) : undefined,
        max: max_height_min ? parseInt(max_height_min) : undefined,
      });
    }

    // 高度検索 ロッド重量の初期値を設定
    const min_weight = params.get('min_weight');
    const max_weight = params.get('max_weight');
    if (min_weight || max_weight) {
      setSelectedRodWeight({
        min: min_weight ? parseInt(min_weight) : undefined,
        max: max_weight ? parseInt(max_weight) : undefined,
      });
    }

    // 高度検索 リール最大ドラグ力の初期値を設定
    const min_drag_power = params.get('min_drag_power');
    const max_drag_power = params.get('max_drag_power');
    if (min_drag_power || max_drag_power) {
      setSelectedDragPower({
        min: min_drag_power ? parseInt(min_drag_power) : undefined,
        max: min_drag_power ? parseInt(min_drag_power) : undefined,
      });
    }

    // 高度検索 糸巻き量（ナイロン）の初期値を設定
    const line_nylon_go = params.get('line_nylon_go');
    const line_capacity_nylon_go = params.get('line_capacity_nylon_go');
    const line_nylon_lb = params.get('line_nylon_lb');
    const line_capacity_nylon_lb = params.get('line_capacity_nylon_lb');
    const line_fluorocarbon_go = params.get('line_fluorocarbon_go');
    const line_capacity_fluorocarbon_go = params.get(
      'line_capacity_fluorocarbon_go'
    );
    const line_fluorocarbon_lb = params.get('line_fluorocarbon_lb');
    const line_capacity_fluorocarbon_lb = params.get(
      'line_capacity_fluorocarbon_lb'
    );
    const line_pe_go = params.get('line_pe_go');
    const line_capacity_pe_go = params.get('line_capacity_pe_go');
    const line_pe_lb = params.get('line_pe_lb');
    const line_capacity_pe_lb = params.get('line_capacity_pe_lb');
    if (line_nylon_go) {
      setSelectedLineNylonGo(parseInt(line_nylon_go));
    }
    if (line_capacity_nylon_go) {
      setSelectedLineCapacityNylonGo(parseInt(line_capacity_nylon_go));
    }
    if (line_nylon_lb) {
      setSelectedLineNylonLb(parseInt(line_nylon_lb));
    }
    if (line_capacity_nylon_lb) {
      setSelectedLineCapacityNylonLb(parseInt(line_capacity_nylon_lb));
    }
    if (line_fluorocarbon_go) {
      setSelectedLineFluorocarbonGo(parseInt(line_fluorocarbon_go));
    }
    if (line_capacity_fluorocarbon_go) {
      setSelectedLineCapacityFluorocarbonGo(
        parseInt(line_capacity_fluorocarbon_go)
      );
    }
    if (line_fluorocarbon_lb) {
      setSelectedLineFluorocarbonLb(parseInt(parseInt(line_fluorocarbon_lb)));
    }
    if (line_capacity_fluorocarbon_lb) {
      setSelectedLineCapacityFluorocarbonLb(
        parseInt(line_capacity_fluorocarbon_lb)
      );
    }
    if (line_pe_go) {
      setSelectedLinePeGo(parseInt(line_pe_go));
    }
    if (line_capacity_pe_go) {
      setSelectedLineCapacityPeGo(parseInt(line_capacity_pe_go));
    }
    if (line_pe_lb) {
      setSelectedLinePeLb(parseInt(line_pe_lb));
    }
    if (line_capacity_pe_lb) {
      setSelectedLineCapacityPeLb(parseInt(line_capacity_pe_lb));
    }

    // 高度検索 ライン素材の初期値を設定
    const lineCategories = params.get('line_categories');
    if (lineCategories) {
      setSelectedLineCategories(
        lineCategories.split(',').map(id => parseInt(id))
      );
    }

    // 高度検索 ライン号数/ポンド数の初期値を設定
    const min_line_size_go = params.get('min_line_size_go');
    const max_line_size_go = params.get('max_line_size_go');
    if (min_line_size_go || max_line_size_go) {
      setSelectedLineSizeGo({
        min: min_line_size_go ? parseInt(min_line_size_go) : undefined,
        max: max_line_size_go ? parseInt(max_line_size_go) : undefined,
      });
    }
    const min_line_size_lb = params.get('min_line_size_lb');
    const max_line_size_lb = params.get('max_line_size_lb');
    if (min_line_size_lb || max_line_size_lb) {
      setSelectedLineSizeLb({
        min: min_line_size_lb ? parseInt(min_line_size_lb) : undefined,
        max: max_line_size_lb ? parseInt(max_line_size_lb) : undefined,
      });
    }

    // 高度検索 ライン巻き量の初期値を設定
    const min_line_capacity = params.get('min_line_capacity');
    const max_line_capacity = params.get('max_line_capacity');
    if (min_line_capacity || max_line_capacity) {
      setSelectedLineCapacity({
        min: min_line_capacity ? parseInt(min_line_capacity) : undefined,
        max: max_line_capacity ? parseInt(max_line_capacity) : undefined,
      });
    }

    // 高度検索 ラインカラーの初期値を設定
    const lineColors = params.get('line_colors');
    if (lineColors) {
      setSelectedLineColors(lineColors.split(',').map(id => parseInt(id)));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // ページを非同期にしてリッチコンテンツにし始めた際にケアしたい

  useEffect(() => {
    // タックル種別が変更されたらsubmit
    if (initialTackleType.current !== tackleType) {
      if (formRef.current) {
        formRef.current.submit();
      }
    }
  }, [tackleType]);

  // メーカー検索の初期値をparamsのmaker_idsから設定
  const filterMakersByIds = (makerIds: Array<number>) => {
    const filteredMakersByIds = makers.filter(maker =>
      makerIds.includes(maker.id)
    );
    setInitialMakers(filteredMakersByIds);
    setSelectedMakers(filteredMakersByIds);
    setSelectedMakerNames(filteredMakersByIds.map(maker => maker.name_en));
  };

  // メーカー検索の初期値をparamsのmaker_idsから設定
  const filterFishingMethodsByIds = (fishingMethodIds: Array<number>) => {
    const filteredFishingMethodsByIds = fishingMethods.filter(fishingMethod =>
      fishingMethodIds.includes(fishingMethod.id)
    );
    setInitialFishingMethods(filteredFishingMethodsByIds);
    setSelectedFishingMethods(filteredFishingMethodsByIds);
    setSelectedFishingMethodNames(
      filteredFishingMethodsByIds.map(fishingMethod => fishingMethod.name)
    );
  };

  // キーワードをstateに反映
  const handleKeywords = (keywords: string, type: string) => {
    switch (type) {
      case 'maker':
        if (keywords === '') setFilteredMakers(makers);
        setMakerKeywords(keywords);
        break;
      case 'fishingMethod':
        if (keywords === '') setFilteredFishingMethods(fishingMethods);
        setFishingMethodKeywords(keywords);
        break;
      case 'fish':
        if (keywords === '') setFilteredFishes(fishes);
        setFishKeywords(keywords);
        break;
      default:
        if (keywords === '') setFilteredMakers(makers);
        setFishKeywords(keywords);
        break;
    }
  };

  // modalで選択したメーカーをstateに反映
  const updateSelectedMakers = (selectedMaker: Maker) => {
    if (selectedMakers.find(maker => maker.id === selectedMaker.id)) {
      const filteredMakers = selectedMakers.filter(
        maker => maker.id !== selectedMaker.id
      );
      const filteredItems = selectedMakerNames.filter(
        item => item !== selectedMaker.name_en
      );
      setSelectedMakers(filteredMakers);
      setSelectedMakerNames(filteredItems);
    } else {
      setSelectedMakers([...selectedMakers, selectedMaker]);
      setSelectedMakerNames([...selectedMakerNames, selectedMaker.name_en]);
    }
  };

  // modalで選択した魚種をstateに反映
  const updateSelectedFishes = (selectedFish: Fish) => {
    if (selectedFishes.find(fish => fish.id === selectedFish.id)) {
      const filteredFishes = selectedFishes.filter(
        fish => fish.id !== selectedFish.id
      );
      const filteredItems = selectedFishingMethodNames.filter(
        item => item !== selectedFish.name
      );
      setSelectedFishes(filteredFishes);
      setSelectedFishNames(filteredItems);
    } else {
      setSelectedFishes([...selectedFishes, selectedFish]);
      setSelectedFishNames([...selectedFishNames, selectedFish.name]);
    }
  };

  // modalで選択した釣り方をstateに反映
  const updateSelectedFishingMethods = (
    selectedFishingMethod: FishingMethod
  ) => {
    if (
      selectedFishingMethods.find(
        fishingMethod => fishingMethod.id === selectedFishingMethod.id
      )
    ) {
      const filteredFishingMethods = selectedFishingMethods.filter(
        fishingMethod => fishingMethod.id !== selectedFishingMethod.id
      );
      const filteredItems = selectedFishingMethodNames.filter(
        fishingMethod => fishingMethod !== selectedFishingMethod.name
      );
      setSelectedFishingMethods(filteredFishingMethods);
      setSelectedFishingMethodNames(filteredItems);
    } else {
      setSelectedFishingMethods([
        ...selectedFishingMethods,
        selectedFishingMethod,
      ]);
      setSelectedFishingMethodNames([
        ...selectedFishingMethodNames,
        selectedFishingMethod.name,
      ]);
    }
  };

  // キーワード検索時に候補を絞り込み、filteredMakers、filteredFishingMethods、filteredFishesに反映
  const filterItems = (key: string, type: string) => {
    if (key !== 'Enter') return;

    if (type === 'maker') {
      const makerRegex = new RegExp(hiraToKana(makerKeywords), 'gi');
      const makerResult = makers.filter(
        maker =>
          maker.name_en.match(makerRegex) ||
          hiraToKana(maker.name_jp).match(makerRegex)
      );
      setFilteredMakers(makerResult);
    } else if (type === 'fishingMethod') {
      const fishingMethodRegex = new RegExp(
        hiraToKana(fishingMethodKeywords),
        'gi'
      );
      const fishingMethodResult = fishingMethods.filter(fishingMethod =>
        fishingMethod.name.match(fishingMethodRegex)
      );
      setFilteredFishingMethods(fishingMethodResult);
    } else if (type === 'fish') {
      const fishRegex = new RegExp(hiraToKana(fishKeywords), 'gi');
      const fishResult = fishes.filter(fish => fish.name.match(fishRegex));
      setFilteredFishes(fishResult);
    }
  };

  // ひらがなをカタカナに変換
  const hiraToKana = (text: string) => {
    return text.replace(/[\u3041-\u3096]/g, match => {
      const chr = match.charCodeAt(0) + 0x60;
      return String.fromCharCode(chr);
    });
  };

  // 数字を通貨表記に変換
  const numberToCurrency = (number: number) => {
    return `${String(number).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}円`;
  };

  const isSearchParamChanged = () => {
    return (
      JSON.stringify(initialMakers) !== JSON.stringify(selectedMakers) ||
      JSON.stringify(initialFishingMethods) !==
        JSON.stringify(selectedFishingMethods) ||
      initialPriceRange.min !== selectedPriceRange.min ||
      initialPriceRange.max !== selectedPriceRange.max
    );
  };

  const value = {
    // state of makers
    selectedMakers,
    setSelectedMakers,
    selectedMakerNames,
    setSelectedMakerNames,
    initialMakers,
    setInitialMakers,
    filteredMakers,
    setFilteredMakers,
    makerKeywords,
    // state of fishes
    setFilteredFishes,
    setSelectedFishes,
    selectedFishes,
    selectedFishNames,
    filteredFishes,
    fishKeywords,
    // state of fishingMethods
    selectedFishingMethods,
    setSelectedFishingMethods,
    selectedFishingMethodNames,
    setSelectedFishingMethodNames,
    initialFishingMethods,
    setInitialFishingMethods,
    filteredFishingMethods,
    setFilteredFishingMethods,
    fishingMethodKeywords,
    // state of priceRange
    selectedPriceRange,
    setSelectedPriceRange,
    initialPriceRange,
    setInitialPriceRange,
    // state of score
    selectedScore,
    setSelectedScore,
    // state of rodHeight
    selectedRodHeightM,
    setSelectedRodHeightM,
    selectedRodHeightFt,
    setSelectedRodHeightFt,
    // state of rodPiece
    selectedRodPiece,
    setSelectedRodPiece,
    // state of rodHeightMin
    selectedRodHeightMin,
    setSelectedRodHeightMin,
    // state of rodWeight
    selectedRodWeight,
    setSelectedRodWeight,
    // state of dragPower
    selectedDragPower,
    setSelectedDragPower,
    // state of lineCapacity
    selectedLineNylonGo,
    setSelectedLineNylonGo,
    selectedLineNylonLb,
    setSelectedLineNylonLb,
    selectedLineFluorocarbonGo,
    setSelectedLineFluorocarbonGo,
    selectedLineFluorocarbonLb,
    setSelectedLineFluorocarbonLb,
    selectedLinePeGo,
    setSelectedLinePeGo,
    selectedLinePeLb,
    setSelectedLinePeLb,
    selectedLineCapacityNylonGo,
    setSelectedLineCapacityNylonGo,
    selectedLineCapacityNylonLb,
    setSelectedLineCapacityNylonLb,
    selectedLineCapacityFluorocarbonGo,
    setSelectedLineCapacityFluorocarbonGo,
    selectedLineCapacityFluorocarbonLb,
    setSelectedLineCapacityFluorocarbonLb,
    selectedLineCapacityPeGo,
    setSelectedLineCapacityPeGo,
    selectedLineCapacityPeLb,
    setSelectedLineCapacityPeLb,
    // state of lineCategory
    selectedLineCategories,
    setSelectedLineCategories,
    // state of lineSize
    selectedLineSizeGo,
    setSelectedLineSizeGo,
    selectedLineSizeLb,
    setSelectedLineSizeLb,
    // state of lineCapacity
    selectedLineCapacity,
    setSelectedLineCapacity,
    // state of lineColor
    selectedLineColors,
    setSelectedLineColors,
    // state of filterType
    setTackleType,
    tackleType,
    // function
    handleKeywords,
    updateSelectedMakers,
    updateSelectedFishes,
    updateSelectedFishingMethods,
    filterItems,
    filterMakersByIds,
    numberToCurrency,
    isSearchParamChanged,
    // ref
    formRef,
  };

  return (
    <SearchParamsContext.Provider value={value}>
      {children}
    </SearchParamsContext.Provider>
  );
};

// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import { useSearchParamsContext } from '../SearchParamsProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faCheck,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

type Props = {
  onPressBack: () => void,
  onCloseModal: () => void,
};

const LineCapacityModal = ({ onPressBack, onCloseModal }: Props): Node => {
  const {
    selectedLineCapacity,
    setSelectedLineCapacity,
  } = useSearchParamsContext();
  const [lineCapacity, setLineCapacity] = useState<{
    min: ?number,
    max: ?number,
  }>({
    min: selectedLineCapacity.min,
    max: selectedLineCapacity.max,
  });

  const onPressClear = () => {
    setLineCapacity({ min: undefined, max: undefined });
  };

  const onSubmit = () => {
    setSelectedLineCapacity(lineCapacity);
    onPressBack();
  };

  return (
    <div data-scope-path="tacklebox/common/modal">
      <div className={`modal modal-active`}>
        <div className="modal-header">
          <div onClick={onPressBack} className="modal-back">
            <FontAwesomeIcon
              icon={faAngleLeft}
              size="xs"
              className="angle_left_icon"
            />
            戻る
          </div>
          <div>ライン-巻き量を選択</div>
          <FontAwesomeIcon
            icon={faXmark}
            size="xs"
            className="close_icon"
            onClick={() => onCloseModal()}
          />
        </div>
        <div className="modal-body">
          <div className="display_flex flex_wrap align_center fz_sub fc_sub">
            <span onClick={onPressClear} className="fc_sub">
              <i className="fa fa-times fz_sub-headline" aria-hidden="true"></i>
              条件をクリア
            </span>
          </div>
        </div>
        <div className="modal-scroll-body">
          <div className="more-filter-list">
            <div className="pure-u-1">
              <input
                value={lineCapacity.min}
                type="number"
                placeholder="m"
                onChange={e =>
                  setLineCapacity({
                    ...lineCapacity,
                    min: e.target.value,
                  })
                }
              />
            </div>
            <div className="p_3">〜</div>
            <div className="pure-u-1">
              <input
                value={lineCapacity.max}
                type="number"
                placeholder="m"
                onChange={e =>
                  setLineCapacity({
                    ...lineCapacity,
                    max: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            onClick={onSubmit}
            className="btn_primary submit_button"
          >
            <FontAwesomeIcon icon={faCheck} size="xs" />
            決定
          </button>
        </div>
      </div>
    </div>
  );
};

export default LineCapacityModal;

// @flow

window.views = {};

window.addEventListener('beforeunload', function() {
  const element = document.getElementById('loading');
  if (element && element.classList.contains('display_none')) {
    element.classList.remove('display_none');
  }
});
window.addEventListener('pageshow', function() {
  const element = document.getElementById('loading');
  if (element && element.classList.contains('display_none') == false) {
    element.classList.add('display_none');
  }
});

// for react-rails
// react-railsを使うための設定なのでESLintとFlowのルールを無視する
// $FlowFixMe
var componentRequireContext = require.context('tacklebox/views', true);
var ReactRailsUJS = require('react_ujs');
// eslint-disable-next-line react-hooks/rules-of-hooks
ReactRailsUJS.useContext(componentRequireContext);

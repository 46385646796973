// @flow
import React, { useState, useEffect } from 'react';
import { useSearchParamsContext } from './SearchParamsProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

type Props = {
  children: React$Node,
};

const SearchBar = ({ children }: Props): React$Node => {
  const [newKeywords, setNewKeywords] = useState('');
  const {
    selectedMakers,
    selectedFishes, // eslint-disable-line no-unused-vars
    selectedFishingMethods,
    selectedPriceRange,
    selectedScore,
    tackleType,
    // Rod
    selectedRodHeightM,
    selectedRodHeightFt,
    selectedRodHeightMin,
    selectedRodPiece,
    selectedRodWeight,
    // Reel
    selectedDragPower,
    selectedLineNylonGo,
    selectedLineCapacityNylonGo,
    selectedLineNylonLb,
    selectedLineCapacityNylonLb,
    selectedLineFluorocarbonGo,
    selectedLineCapacityFluorocarbonGo,
    selectedLineFluorocarbonLb,
    selectedLineCapacityFluorocarbonLb,
    selectedLinePeGo,
    selectedLineCapacityPeGo,
    // Line
    selectedLineCategories,
    selectedLineSizeGo,
    selectedLineSizeLb,
    selectedLineCapacity,
    selectedLineColors,
    //
    formRef,
  } = useSearchParamsContext();
  const makerIds = selectedMakers.map(maker => maker.id);
  const fishingMethodIds = selectedFishingMethods.map(fm => fm.id);
  // フェーズ1では魚種検索しない
  // const fishIds = selectedFishes.map(fish => fish.id);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const keywords = params.get('keywords');
    if (keywords) {
      setNewKeywords(keywords);
    }
  }, []);

  return (
    <form
      data-scope-path="react_components/tacklebox/common/search_bar_component"
      ref={formRef}
      action={window.location.pathname}
      method="get"
    >
      <input type="hidden" name="keywords" value={newKeywords} />
      <input type="hidden" name="maker_ids" value={makerIds || ''} />
      {/* フェーズ1では魚種検索しない */}
      {/* <input type="hidden" name="fish_ids" value={fishIds} /> */}
      <input
        type="hidden"
        name="fishing_method_ids"
        value={fishingMethodIds || ''}
      />
      <input
        type="hidden"
        name="min_price"
        value={selectedPriceRange['min'] || ''}
      />
      <input
        type="hidden"
        name="max_price"
        value={selectedPriceRange['max'] || ''}
      />
      <input
        type="hidden"
        name="min_score"
        value={selectedScore['min'] || ''}
      />
      <input
        type="hidden"
        name="max_score"
        value={selectedScore['max'] || ''}
      />
      <input
        type="hidden"
        name="min_height_m"
        value={selectedRodHeightM['min'] || ''}
      />
      <input
        type="hidden"
        name="max_height_m"
        value={selectedRodHeightM['max'] || ''}
      />
      <input
        type="hidden"
        name="min_height_ft"
        value={selectedRodHeightFt['min'] || ''}
      />
      <input
        type="hidden"
        name="max_height_ft"
        value={selectedRodHeightFt['max'] || ''}
      />
      <input
        type="hidden"
        name="min_height_min"
        value={selectedRodHeightMin['min'] || ''}
      />
      <input
        type="hidden"
        name="max_height_min"
        value={selectedRodHeightMin['max'] || ''}
      />
      <input
        type="hidden"
        name="min_pieces"
        value={selectedRodPiece['min'] || ''}
      />
      <input
        type="hidden"
        name="max_pieces"
        value={selectedRodPiece['max'] || ''}
      />
      <input
        type="hidden"
        name="min_weight"
        value={selectedRodWeight['min'] || ''}
      />
      <input
        type="hidden"
        name="max_weight"
        value={selectedRodWeight['max'] || ''}
      />
      <input
        type="hidden"
        name="min_drag_power"
        value={selectedDragPower['min'] || ''}
      />
      <input
        type="hidden"
        name="max_drag_power"
        value={selectedDragPower['max'] || ''}
      />
      <input
        type="hidden"
        name="line_nylon_go"
        value={selectedLineNylonGo || ''}
      />
      <input
        type="hidden"
        name="line_capacity_nylon_go"
        value={selectedLineCapacityNylonGo || ''}
      />
      <input
        type="hidden"
        name="line_nylon_lb"
        value={selectedLineNylonLb || ''}
      />
      <input
        type="hidden"
        name="line_capacity_nylon_lb"
        value={selectedLineCapacityNylonLb || ''}
      />
      <input
        type="hidden"
        name="line_fluorocarbon_go"
        value={selectedLineFluorocarbonGo || ''}
      />
      <input
        type="hidden"
        name="line_capacity_fluorocarbon_go"
        value={selectedLineCapacityFluorocarbonGo || ''}
      />
      <input
        type="hidden"
        name="line_fluorocarbon_lb"
        value={selectedLineFluorocarbonLb || ''}
      />
      <input
        type="hidden"
        name="line_capacity_fluorocarbon_lb"
        value={selectedLineCapacityFluorocarbonLb || ''}
      />
      <input type="hidden" name="line_pe_go" value={selectedLinePeGo || ''} />
      <input
        type="hidden"
        name="line_capacity_pe_go"
        value={selectedLineCapacityPeGo || ''}
      />
      <input
        type="hidden"
        name="line_categories"
        value={selectedLineCategories || ''}
      />
      <input
        type="hidden"
        name="min_line_size_go"
        value={selectedLineSizeGo['min'] || ''}
      />
      <input
        type="hidden"
        name="max_line_size_go"
        value={selectedLineSizeGo['max'] || ''}
      />
      <input
        type="hidden"
        name="min_line_size_lb"
        value={selectedLineSizeLb['min'] || ''}
      />
      <input
        type="hidden"
        name="max_line_size_lb"
        value={selectedLineSizeLb['max'] || ''}
      />
      <input
        type="hidden"
        name="min_line_capacity"
        value={selectedLineCapacity['max'] || ''}
      />
      <input
        type="hidden"
        name="max_line_capacity"
        value={selectedLineCapacity['max'] || ''}
      />
      <input
        type="hidden"
        name="line_colors"
        value={selectedLineColors || ''}
      />
      <input type="hidden" name="type" value={tackleType || ''} />
      <div className="search_container">
        <input
          className="search_input mahimahi-embed-suggest-search-input" // mahimahiの検索サジェスト用class
          placeholder="キーワードでさがす"
          value={newKeywords}
          onChange={event => setNewKeywords(event.target.value)}
        />
        <button className="search_button" type="submit">
          <FontAwesomeIcon icon={faSearch} size="xs" />
        </button>
      </div>

      {children}
    </form>
  );
};

export default SearchBar;

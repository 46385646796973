// @flow
import React, { useState } from 'react';
import type { TackleReel } from '../../models/tackle_reels/TackleReel';
import { TACKLE_REEL_COLUMN_DEFINITIONS } from '../../models/tackle_reels/TackleReel';
import FavoriteModal from './FavoriteModal';
import ReelFavoriteClient from '../../models/favorites/ReelFavoriteClient';

type Props = {
  tackleReels: Array<TackleReel>,
  token: string,
};

type Columns = {
  key: string,
  label: string,
};

function ReelTableRoot(props: Props) {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <ReelTable {...props} />;
}

function ReelTable({ tackleReels, token }: Props) {
  const [visible, setVisible] = useState<boolean>(false);
  const [reelId, setReelId] = useState<number>(0);
  const [maskedReels, setMaskedReels] = useState<Array<TackleReel>>(
    tackleReels
  );

  const onOpen = (id: number) => {
    setReelId(id);
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onRemove = (id: number) => {
    const reelFavoriteClient = new ReelFavoriteClient();
    reelFavoriteClient
      .removeFavorite(id, token)
      .then(() => {
        setMaskedReels(maskedReels.filter(reel => reel.id != id));
        setVisible(false);
      })
      .catch(error => console.error(error));
  };

  const displayKeys = maskedReels.reduce((acc, reel) => {
    const entries = Object.keys(reel).filter(key => {
      return reel[key] != null && reel[key] !== '';
    });
    return Array.from(new Set([...entries, ...acc]));
  }, []);

  const displayDefinitions: Array<Columns> = TACKLE_REEL_COLUMN_DEFINITIONS.filter(
    definition => {
      return displayKeys.includes(definition.key);
    }
  );

  const reelName = (reel: TackleReel) => {
    return `${reel.series.name_jp} ${reel.name_en}`;
  };

  const reelNameColumn = (reel: TackleReel) => {
    return (
      <div className="list_control">
        <a href={`/reels/${reel.id}`} className="name">
          {reelName(reel)}
        </a>
        <a
          className="remove_list"
          onClick={() => {
            onOpen(reel.id);
          }}
        >
          <i className="las la-times"></i>
        </a>
      </div>
    );
  };

  const displayTableHead = (definitions: Array<Columns>) => {
    return (
      <tr>
        {definitions.map(definition => {
          return <th key={definition.key}>{definition.label}</th>;
        })}
      </tr>
    );
  };

  const displayTableBody = (reels: Array<TackleReel>) => {
    return (
      <>
        {reels.map(reel => {
          return (
            <tr key={reel.id}>
              {displayDefinitions.map(definition => {
                if (definition.key == 'name_en') {
                  return <td key={definition.key}> {reelNameColumn(reel)}</td>;
                } else {
                  return <td key={definition.key}>{reel[definition.key]}</td>;
                }
              })}
            </tr>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div data-scope-path="tacklebox/favorites/comparison_table">
        {(() => {
          if (maskedReels.length == 0) {
            return <p>お気に入りのリールはありません。</p>;
          } else {
            return (
              <table>
                <thead>{displayTableHead(displayDefinitions)}</thead>
                <tbody>{displayTableBody(maskedReels)}</tbody>
              </table>
            );
          }
        })()}
      </div>
      <FavoriteModal
        visible={visible}
        onClose={onClose}
        modelId={reelId}
        onRemove={onRemove}
      />
    </>
  );
}

export default ReelTableRoot;

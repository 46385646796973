// @flow
import type { Maker } from '../makers/Maker';
import type { Series } from '../series/Series';
import type { FishingMethod } from '../fishing_methods/FishingMethod';
import LineColorClearImage from '../../assets/images/views/search/lines/clear.webp';
import LineColorRedImage from '../../assets/images/views/search/lines/red.webp';
import LineColorPinkImage from '../../assets/images/views/search/lines/pink.webp';
import LineColorOrangeImage from '../../assets/images/views/search/lines/orange.webp';
import LineColorYellowImage from '../../assets/images/views/search/lines/yellow.webp';
import LineColorGreenImage from '../../assets/images/views/search/lines/green.webp';
import LineColorBlueImage from '../../assets/images/views/search/lines/blue.webp';
import LineColorBlackImage from '../../assets/images/views/search/lines/black.webp';
import LineColorGrayImage from '../../assets/images/views/search/lines/gray.webp';
import LineColorWhiteImage from '../../assets/images/views/search/lines/white.webp';
import LineColorMulti10Image from '../../assets/images/views/search/lines/multi_10.webp';
import LineColorMulti5Image from '../../assets/images/views/search/lines/multi_5.webp';
import LineColorMultiOtherImage from '../../assets/images/views/search/lines/multi_other.webp';

export type TackleLine = {
  id: number,
  series: Series,
  maker: Maker,
  name: string,
  tackle_line_category_id: number,
  tackle_line_color_family_id: number,
  release_year: ?number,
  go: ?number,
  go2: ?number,
  go_average: ?string,
  knitting_number: ?number,
  lb_average: ?number,
  lb_max: ?number,
  power_average: ?number,
  power_max: ?number,
  diameter: ?number,
  diameter2: ?number,
  wire_diameter: ?number,
  outer_diameter: ?number,
  nodule_power_average: ?number,
  capacity_length: ?string,
  specific_gravity: ?number,
  wire_size: ?number,
  number_connection: ?number,
  line_piece: ?number,
  color: ?string,
  spool_band: ?string,
  price: ?number,
  is_open_pricing: ?boolean,
  jan_code: ?string,
  url: ?string,
  results_count: ?number,
  reviews_count: ?number,
  total_score: ?number,
  ease_score: ?number,
  toughness_score: ?number,
  cost_score: ?number,
  lowest_price: ?number,
  remarks: ?string,
  fishing_methods: Array<FishingMethod>,
};

export const TACKLE_LINE_COLUMN_DEFINITIONS: Array<{
  key: string,
  label: string,
}> = [
  { key: 'go', label: '号数' },
  { key: 'go2', label: '号数2' },
  { key: 'go_average', label: '参考号数' },
  { key: 'knitting_number', label: '編み本数' },
  { key: 'lb_average', label: '標準lb' },
  { key: 'lb_max', label: '最大lb数' },
  { key: 'power_average', label: '標準強力\n(kg)' },
  { key: 'power_max', label: '最大強力\n(kg)' },
  { key: 'diameter', label: '標準直径\n(mm)' },
  { key: 'diameter2', label: '標準直径2\n(mm)' },
  { key: 'wire_diameter', label: 'ワイヤー径\n(mm)' },
  { key: 'outer_diameter', label: '外径\n(mm)' },
  { key: 'nodule_power_average', label: '標準\n結節強力(kg)' },
  { key: 'capacity_length', label: '巻数(m)' },
  { key: 'specific_gravity', label: '比重' },
  { key: 'wire_size', label: 'ワイヤー\nサイズ' },
  { key: 'number_connection', label: '連結数' },
  { key: 'line_piece', label: '入数' },
  { key: 'color', label: 'ライン\nカラー' },
  { key: 'spool_band', label: 'スプール\nバンド' },
  { key: 'price', label: '希望小売価格' },
];

export const TACKLE_LINE_MATERIAL_COLUMN_DEFINITIONS: Array<{
  key: number,
  label: string,
}> = [
  { key: 1, label: 'ナイロンライン' },
  { key: 2, label: 'フロロカーボン' },
  { key: 3, label: 'PEライン' },
  { key: 4, label: 'エステルライン' },
];

export const TACKLE_LINE_COLOR_COLUMN_DEFINITIONS: Array<{
  key: number,
  label: string,
  icon: string,
}> = [
  { key: 12, label: 'クリア', icon: LineColorClearImage },
  { key: 1, label: 'レッド', icon: LineColorRedImage },
  { key: 6, label: 'ピンク', icon: LineColorPinkImage },
  { key: 2, label: 'オレンジ', icon: LineColorOrangeImage },
  { key: 3, label: 'イエロー', icon: LineColorYellowImage },
  { key: 4, label: 'グリーン', icon: LineColorGreenImage },
  { key: 5, label: 'ブルー', icon: LineColorBlueImage },
  { key: 9, label: 'ブラック', icon: LineColorBlackImage },
  { key: 13, label: 'グレー', icon: LineColorGrayImage },
  { key: 10, label: 'ホワイト', icon: LineColorWhiteImage },
  { key: 19, label: '10色', icon: LineColorMulti10Image },
  { key: 18, label: '5色', icon: LineColorMulti5Image },
  { key: 0, label: 'マルチカラー\nその他', icon: LineColorMultiOtherImage },
];

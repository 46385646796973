// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import { useSearchParamsContext } from '../SearchParamsProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faAngleUp,
  faAngleDown,
  faCheck,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

type Props = {
  onPressBack: () => void,
  onCloseModal: () => void,
};

const LineSizeModal = ({ onPressBack, onCloseModal }: Props): Node => {
  const {
    selectedLineSizeGo,
    setSelectedLineSizeGo,
    selectedLineSizeLb,
    setSelectedLineSizeLb,
  } = useSearchParamsContext();
  const [lineSizeGo, setLineSizeGo] = useState<{
    min: ?number,
    max: ?number,
  }>({
    min: selectedLineSizeGo.min,
    max: selectedLineSizeGo.max,
  });
  const [lineSizeLb, setLineSizeLb] = useState<{
    min: ?number,
    max: ?number,
  }>({
    min: selectedLineSizeLb.min,
    max: selectedLineSizeLb.max,
  });

  const [visibleGo, setVisibleGo] = useState(false);
  const [visibleLb, setVisibleLb] = useState(false);

  const onPressClear = () => {
    setLineSizeGo({ min: undefined, max: undefined });
    setLineSizeLb({ min: undefined, max: undefined });
  };

  const onSubmit = () => {
    setSelectedLineSizeGo(lineSizeGo);
    setSelectedLineSizeLb(lineSizeLb);
    onPressBack();
  };

  const onPressGo = () => {
    setVisibleGo(!visibleGo);
  };
  const onPressLb = () => {
    setVisibleLb(!visibleLb);
  };

  return (
    <div data-scope-path="tacklebox/common/modal">
      <div className={`modal modal-active`}>
        <div className="modal-header">
          <div onClick={onPressBack} className="modal-back">
            <FontAwesomeIcon
              icon={faAngleLeft}
              size="xs"
              className="angle_left_icon"
            />
            戻る
          </div>
          <div>ライン-号数/ポンド数を選択</div>
          <FontAwesomeIcon
            icon={faXmark}
            size="xs"
            className="close_icon"
            onClick={() => onCloseModal()}
          />
        </div>
        <div className="modal-body">
          <div className="display_flex flex_wrap align_center fz_sub fc_sub">
            <span onClick={onPressClear} className="fc_sub">
              <i className="fa fa-times fz_sub-headline" aria-hidden="true"></i>
              条件をクリア
            </span>
          </div>
        </div>
        <div className="modal-scroll-body">
          <div className="display_flex flex_wrap inner">
            <div className="checkbox" onClick={onPressGo}>
              <div className="list_label">号数から探す</div>
              <div className="selected_values">
                <FontAwesomeIcon
                  icon={visibleGo ? faAngleUp : faAngleDown}
                  size="xs"
                  className="right_icon"
                />
              </div>
            </div>
            {visibleGo && (
              <div className="more-filter-list">
                <div className="pure-u-1">
                  <input
                    value={lineSizeGo.min}
                    type="number"
                    placeholder="号"
                    onChange={e =>
                      setLineSizeGo({
                        ...lineSizeGo,
                        min: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="p_3">〜</div>
                <div className="pure-u-1">
                  <input
                    value={lineSizeGo.max}
                    type="number"
                    placeholder="号"
                    onChange={e =>
                      setLineSizeGo({
                        ...lineSizeGo,
                        max: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>
          <div className="display_flex flex_wrap inner">
            <div className="checkbox" onClick={onPressLb}>
              <div className="list_label">ポンド(lb)から探す</div>
              <div className="selected_values">
                <FontAwesomeIcon
                  icon={visibleLb ? faAngleUp : faAngleDown}
                  size="xs"
                  className="right_icon"
                />
              </div>
            </div>
            {visibleLb && (
              <div className="more-filter-list">
                <div className="pure-u-1">
                  <input
                    value={lineSizeLb.min}
                    type="number"
                    placeholder="ポンド"
                    onChange={e =>
                      setLineSizeLb({
                        ...lineSizeLb,
                        min: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="p_3">〜</div>
                <div className="pure-u-1">
                  <input
                    value={lineSizeLb.max}
                    type="number"
                    placeholder="ポンド"
                    onChange={e =>
                      setLineSizeLb({
                        ...lineSizeLb,
                        max: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            onClick={onSubmit}
            className="btn_primary submit_button"
          >
            <FontAwesomeIcon icon={faCheck} size="xs" />
            決定
          </button>
        </div>
      </div>
    </div>
  );
};

export default LineSizeModal;

// @flow
import React from 'react';
import type { Node } from 'react';
import type { Advertisement } from '../../models/advertisements/Advertisement';
type Props = {
  advertisement: Advertisement,
  index: number,
  base_price: ?number,
  linkType?: 'detail' | 'lowestPrice',
};

function ShopLink({ advertisement, index, linkType }: Props): Node {
  if (!advertisement.sponsor) {
    return null;
  }
  const onClick = (ad: Advertisement) => {
    // $FlowFixMe
    clickAdvertisement(ad.id);
  };
  const sponsorNameLable = (sponsorName): string => {
    switch (sponsorName) {
      case 'Amazon':
        return 'Amazon';
      case 'Yahoo':
        return 'Y!ショッピング';
      case 'Rakuten':
        return '楽天市場';
      default:
        return '';
    }
  };
  const label =
    sponsorNameLable(advertisement.sponsor.name) +
    (linkType === 'lowestPrice' ? 'で最安値で購入' : 'で詳細を見る');
  return (
    <a
      data-scope-path="react_components/tacklebox/models/shop_link_component"
      className={`ga4_af_click${index} ga4_af_click_${advertisement.sponsor.name}`}
      href={advertisement.url}
      onClick={() => onClick(advertisement)}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div
        className={`shop_link_contents shop_link_${advertisement.sponsor.name.toLocaleLowerCase()}`}
      >
        <div className="shop_link_label fw_700">{label}</div>
        <div className={`shop_link_price fw_500 shop_link_price${index}`}>
          {advertisement.price
            ? `¥${advertisement.price.toLocaleString()}`
            : `¥ -`}
        </div>
      </div>
    </a>
  );
}

export default ShopLink;

// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import { useSearchParamsContext } from './SearchParamsProvider';
import type { Maker } from '../../models/makers/Maker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faCheck,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

type Props = {
  onPressBack: () => void,
  onCloseModal: () => void,
};

const MakerModal = ({ onPressBack, onCloseModal }: Props): Node => {
  const {
    selectedMakers,
    selectedMakerNames,
    filteredMakers,
    setSelectedMakers,
    setSelectedMakerNames,
    makerKeywords,
    handleKeywords,
    filterItems,
  } = useSearchParamsContext();
  const [makers, setMakers] = useState<Array<Maker>>(selectedMakers);
  const [makerNames, setMakerNames] = useState<Array<string>>(
    selectedMakerNames
  );

  const updateMakers = (selectedMaker: Maker) => {
    if (makers.find(maker => maker.id === selectedMaker.id)) {
      const filteredMakers = makers.filter(
        maker => maker.id !== selectedMaker.id
      );
      const filteredItems = makerNames.filter(
        item => item !== selectedMaker.name_en
      );
      setMakers(filteredMakers);
      setMakerNames(filteredItems);
    } else {
      setMakers([...makers, selectedMaker]);
      setMakerNames([...makerNames, selectedMaker.name_en]);
    }
  };

  const onPressClear = () => {
    setMakers([]);
    setMakerNames([]);
  };

  const onSubmit = () => {
    setSelectedMakers(makers);
    setSelectedMakerNames(makerNames);
    onPressBack();
  };

  return (
    <div data-scope-path="tacklebox/common/modal">
      <div className={`modal modal-active`}>
        <div className="modal-header">
          <div onClick={onPressBack} className="modal-back">
            <FontAwesomeIcon
              icon={faAngleLeft}
              size="xs"
              className="angle_left_icon"
            />
            戻る
          </div>
          <div>メーカーを選択</div>
          <FontAwesomeIcon
            icon={faXmark}
            size="xs"
            className="close_icon"
            onClick={() => onCloseModal()}
          />
        </div>
        <div className="modal-body">
          <input
            type="text"
            className="pure-u-1"
            placeholder="キーワード"
            value={makerKeywords}
            onChange={e => handleKeywords(e.target.value, 'maker')}
            onKeyDown={e => filterItems(e.key, 'maker')}
          />
          <div className="display_flex flex_wrap mt_5 align_center fz_sub fc_sub">
            <div className="mr_3">選択中</div>
            {makers.map(maker => (
              <div className="mr_3" key={maker.id}>
                {maker.name_en}
              </div>
            ))}
            <span onClick={onPressClear} className="fc_sub">
              <i className="fa fa-times fz_sub-headline" aria-hidden="true"></i>
              条件をクリア
            </span>
          </div>
        </div>
        <div className="modal-scroll-body">
          <div className="display_flex flex_wrap inner">
            {filteredMakers.map(maker => (
              <div
                className={`maker-checkbox ${
                  makers.find(selectedMaker => selectedMaker.id === maker.id)
                    ? 'active'
                    : ''
                }`}
                key={maker.id}
                onClick={() => updateMakers(maker)}
              >
                <img src={maker.image_url} />
                <input type="checkbox" />
                <label>
                  <div className="name_en">{maker.name_en}</div>
                  <div className="name_jp">{maker.name_jp}</div>
                  <FontAwesomeIcon
                    icon={faCheck}
                    size="xs"
                    className="check_icon"
                  />
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            onClick={onSubmit}
            className="btn_primary submit_button"
          >
            <FontAwesomeIcon icon={faCheck} size="xs" />
            決定
          </button>
        </div>
      </div>
    </div>
  );
};

export default MakerModal;

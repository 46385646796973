// @flow
import type { Node } from 'react';
import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PhotoSwipe from 'photoswipe';
import 'photoswipe/src/photoswipe.css';

type Image = {
  src: string,
  thumb_src: string,
  width: number,
  height: number,
};

type Props = {
  images: Array<Image>,
};

function ReviewImagesRoot(props: Props): Node {
  return <ReviewImages {...props} />;
}

function ReviewImages({ images }: Props) {
  const rescaledImages = (currentWidth: number) => {
    return images.map(image => {
      const ratio: number = image.width / currentWidth;
      const rescaledHeight: number = image.height / ratio;
      return { src: image.src, width: currentWidth, height: rescaledHeight };
    });
  };

  const onClick = (element: Object) => {
    const target: HTMLImageElement = element.target;
    const currentWidth: number = window.innerWidth;
    const clickedImage: number = Number(target.getAttribute('data-index'));
    const pswp = new PhotoSwipe({
      dataSource: rescaledImages(currentWidth),
      index: clickedImage,
      counter: false,
      initialZoomLevel: 1,
      secondaryZoomLevel: 2,
    });
    pswp.init();
  };

  return (
    <div className="carousel_free">
      {images.map((image, index) => (
        <div key={index} className="carousel_item">
          <img
            className="img-fluid"
            src={image.thumb_src}
            onClick={onClick}
            data-index={index}
          />
        </div>
      ))}
    </div>
  );
}

export default ReviewImagesRoot;

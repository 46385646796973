// @flow
import React, { useState } from 'react';

import type { Advertisement } from '../../models/advertisements/Advertisement';

import ShopCard from './ShopCard';

type Props = {
  advertisements: Array<Advertisement>,
  base_price: ?number,
};

function ShopCardListRoot(props: Props): React$Node {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <ShopCardList {...props} />;
}

function ShopCardList({ advertisements, base_price }: Props) {
  const [displayAdvertisements, setDisplayAdvertisements] = useState(
    advertisements.slice(0, 3)
  );

  const onClick = () => {
    setDisplayAdvertisements(advertisements);
  };

  const shouldDisplayShowMoreButton =
    advertisements.length > 3 &&
    displayAdvertisements.length !== advertisements.length;

  return (
    <>
      <div className="display_flex:lg flex_wrap:lg n_m_2">
        {displayAdvertisements.map((value, index) => {
          return (
            <section className="pure-u-lg-1-3 p_2" key={value.id}>
              <ShopCard
                advertisement={value}
                index={index + 1}
                base_price={base_price}
              />
            </section>
          );
        })}
      </div>
      {shouldDisplayShowMoreButton && (
        <div className="display_flex justify_center mt_3">
          <span className="btn_secondary" onClick={onClick}>
            さらに表示
          </span>
        </div>
      )}
    </>
  );
}

export default ShopCardListRoot;

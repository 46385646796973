export const fz_1 = '2.82vw';
export const fz_2 = '3.08vw';
export const fz_3 = '3.59vw';
export const fz_4 = '4.1vw';
export const fz_5 = '4.62vw';
export const fz_6 = '5.13vw';
export const fz_7 = '5.64vw';
export const fz_8 = '6.15vw';

export const fz_1_pc = '11px';
export const fz_2_pc = '12px';
export const fz_3_pc = '14px';
export const fz_4_pc = '16px';
export const fz_5_pc = '18px';
export const fz_6_pc = '20px';
export const fz_7_pc = '22px';
export const fz_8_pc = '24px';

export const lh_xs = '1';
export const lh_sm = '1.5';
export const lh_md = '1.75';

export const shape_1 = '0px';
export const shape_2 = '3px';
export const shape_3 = '12px';
export const shape_4 = '1000px';

export const shadow_xs = '0 2px 4px rgba(#000000, 0.06)';
export const shadow_sm = '0px 0px 12px #0000001f';

export const surf_1 = '#fff5d1';
export const surf_2 = '#ffe68d';
export const surf_3 = '#f6c517';
export const surf_4 = '#dfae00';
export const surf_5 = '#cea100';
export const surf_6 = '#bd9401';
export const surf_7 = '#9e7c00';

export const coral_1 = '#ffefef';
export const coral_2 = '#ffd8d7';
export const coral_3 = '#ffa6a4';
export const coral_4 = '#ff7976';
export const coral_5 = '#e5524e';
export const coral_6 = '#da3b36';
export const coral_7 = '#b72f2c';

export const aqua_1 = '#edf6fd';
export const aqua_2 = '#d4e8f7';
export const aqua_3 = '#9bcbed';
export const aqua_4 = '#54acea';
export const aqua_5 = '#3498db';
export const aqua_6 = '#1985cd';
export const aqua_7 = '#0d6aa8';

export const tuna_1 = '#ffffff';
export const tuna_2 = '#f8f8f8';
export const tuna_3 = '#d5d9dc';
export const tuna_4 = '#878f97';
export const tuna_5 = '#495057';
export const tuna_6 = '#2f3337';
export const tuna_7 = '#171a1c';

export const z_header = 1020;
export const z_modal = `${z_header + 1}`;

export const thumbnail_xs = '24px';
export const thumbnail_sm = '35px';
export const thumbnail_md = '64px';
export const thumbnail_lg = '80px';
export const thumbnail_xl = '120px';

export const screenXs = '576px !default';
export const screenSm = '768px !default';
export const screenMd = '1020px !default';
export const screenLg = '1200px !default';

export const screenXsMax = `(${screenXs} - 1) !default`;
export const screenSmMax = `(${screenMd} - 1) !default`;
export const screenMdMax = `(${screenLg} - 1) !default`;

export const fontFamilySansSerif =
  '-apple-system-subset, BlinkMacSystemFont, "hiragino sans", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "MS Gothic", "ＭＳ ゴシック", "Helvetica Neue", Helvetica, Arial, sans-serif;';

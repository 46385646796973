// @flow
import React from 'react';
import CommentClient from '../../../../../application/models/products/reviews/comments/CommentClient';
import CommentForm from './CommentForm';
import CommentItem from './CommentItem';
import { displayAxiosError } from '../../../../../application/views/commons/messages';
import type { Review } from '../../../../../application/models/products/reviews/Review';
import type { Comment } from '../../../../../application/models/products/reviews/comments/Comment';

type Props = {
  review: Review,
  token: string,
  focusForm: boolean,
  userSignedIn: boolean,
};

type State = {
  comments: Array<Comment>,
};

export default class Comments extends React.Component<Props, State> {
  commentClient: CommentClient = new CommentClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      comments: [],
    };
  }

  componentDidMount() {
    const { review } = this.props;

    if (review?.comments_count === 0) {
      return;
    }

    this.commentClient
      .fetchAll(review)
      .then(response => {
        this.setState({
          comments: response.data.sort((a, b) => a.id - b.id),
        });
      })
      .catch(error => {
        displayAxiosError(error);
      });
  }

  render(): React$Node {
    const { review, token, focusForm, userSignedIn } = this.props;
    const { comments } = this.state;

    return (
      <div className="comments">
        <div className="comment-items">
          {comments.map(comment => (
            <CommentItem
              key={comment.id}
              comment={comment}
              review={review}
              token={token}
              userSignedIn={userSignedIn}
            />
          ))}
          {comments.length === 0 && (
            <div className="comment-nothing">まだコメントはありません</div>
          )}
        </div>
        <div className="comment-description">丁寧な言葉を心がけましょう。</div>
        {userSignedIn && (
          <CommentForm
            visible={true}
            parent={null}
            review={review}
            token={token}
            focus={focusForm}
            onFinishPosting={comment => {
              this.setState({ comments: [...comments, comment] });
            }}
          />
        )}
        {!userSignedIn && (
          <div className="submit-wrap signed_in_wrapper">
            <a href="/users/sign_in" className="comment-submit">
              <i className="fa fa-pencil-square-o"></i>
              ログインしてコメントを残す
            </a>
          </div>
        )}
      </div>
    );
  }
}

// @flow
import React from 'react';
import type { SeriesWithModels } from '../../models/series/SeriesWithModels';
import Stars from '../reviews/Stars';

type Props = {
  series: SeriesWithModels,
};

function SeriesCard({ series }: Props): React$Node {
  const displayLimit = 3;

  const recommendModelPath = models => {
    // modelsに含まれる要素のsort_orderの昇順に並び替える
    const orderedModels = models.sort((a, b) => a.sort_order - b.sort_order);
    // orderedModelsの（length / 2）を切り捨てた値をindexとして取得する
    const centerIndex = Math.floor(orderedModels.length / 2);
    // centerIndexのモデルのtype_model_pathを返す
    return orderedModels[centerIndex].type_model_path;
  };

  return (
    <div className="pure-u-1 pure-u-md-1-2 pure-u-xl-1-3 p_3">
      <section data-scope-path="tacklebox/search/series_card">
        <a href={recommendModelPath(series.models)} className="card_cover">
          <img src={series.image_url} alt="" className="" />
          <div className="cover_details">
            <h1>
              {series.maker && (
                <div className="maker">{series.maker.name_en}</div>
              )}
              <div className="series">
                {series.name_jp ? series.name_jp : series.name_en}
              </div>
            </h1>
            {series.age && <div className="year">{series.age}年モデル</div>}
            <Stars score={series.total_score} starSize="md" />
          </div>
        </a>

        {series.description && (
          <div className="read_text">
            <div className="clamp">{series.description}</div>
          </div>
        )}

        {series.models && series.models.length > 0 && (
          <>
            {series.models.slice(0, displayLimit).map((model, index) => (
              <a
                href={model.type_model_path}
                className="model_container"
                key={index}
              >
                {model.sort_order}
                <div className="model_head">
                  <h2 className="model">{model.name_en}</h2>
                  <div className="price">
                    <span className="price_type">最安値</span>
                    {model.lowest_price ? (
                      <>{model.lowest_price.toLocaleString('ja-JP')}円</>
                    ) : (
                      '--'
                    )}
                  </div>
                </div>

                {model.fishing_methods && (
                  <div className="model_method">
                    {model.fishing_methods.map((fishing_method, index) => (
                      <span key={index}>{fishing_method.name} </span>
                    ))}
                  </div>
                )}
              </a>
            ))}

            {series.models.length > displayLimit && (
              <a
                href={series.type_series_path}
                className="more_items"
                key="more_items"
              >
                さらに{series.models.length - displayLimit}個のアイテム
              </a>
            )}
          </>
        )}
      </section>
    </div>
  );
}

export default SeriesCard;

// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import { useSearchParamsContext } from '../SearchParamsProvider';
import { TACKLE_LINE_COLOR_COLUMN_DEFINITIONS } from '../../../models/tackle_lines/TackleLine';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faCheck,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

type Props = {
  onPressBack: () => void,
  onCloseModal: () => void,
};

const LineColorModal = ({ onPressBack, onCloseModal }: Props): Node => {
  const {
    selectedLineColors,
    setSelectedLineColors,
  } = useSearchParamsContext();

  const multiOtherColors = selectedLineColors
    .filter(colorId => colorId !== 15 && colorId !== 16 && colorId !== 17)
    .concat(0);
  console.log(multiOtherColors);
  const [lineColors, setLineColors] = useState(
    selectedLineColors.includes(15) ? multiOtherColors : selectedLineColors
  );

  const onPressClear = () => {
    setLineColors([]);
  };

  const onSubmit = () => {
    if (lineColors.includes(0)) {
      const list = lineColors.filter(colorId => colorId !== 0);
      setSelectedLineColors(list.concat([15, 16, 17]));
    } else {
      setSelectedLineColors(lineColors);
    }
    onPressBack();
  };

  const updateLineColor = ({ key }) => {
    if (lineColors.includes(key)) {
      const list = lineColors.filter(colorId => colorId !== key);
      setLineColors(list);
    } else {
      setLineColors(lineColors.concat(key));
    }
  };
  const colorNames = lineColors
    .map(
      lineColor =>
        TACKLE_LINE_COLOR_COLUMN_DEFINITIONS.filter(
          color => color.key === lineColor
        )[0].label
    )
    .join('、');
  const colorName = TACKLE_LINE_COLOR_COLUMN_DEFINITIONS.find(
    color => color.key === lineColors[0]
  )?.label;
  const selectedColorNames = lineColors.length === 1 ? colorName : colorNames;

  return (
    <div data-scope-path="tacklebox/common/modal">
      <div className={`modal modal-active`}>
        <div className="modal-header">
          <div onClick={onPressBack} className="modal-back">
            <FontAwesomeIcon
              icon={faAngleLeft}
              size="xs"
              className="angle_left_icon"
            />
            戻る
          </div>
          <div>ライン-カラーを選択</div>
          <FontAwesomeIcon
            icon={faXmark}
            size="xs"
            className="close_icon"
            onClick={() => onCloseModal()}
          />
        </div>
        <div className="modal-body">
          <div className="display_flex flex_wrap mt_5 align_center fz_sub fc_sub">
            <div className="mr_3">選択中</div>
            <div>{selectedColorNames}</div>
            <span onClick={onPressClear} className="fc_sub">
              <i className="fa fa-times fz_sub-headline" aria-hidden="true"></i>
              条件をクリア
            </span>
          </div>
        </div>
        <div className="modal-scroll-body colors">
          {TACKLE_LINE_COLOR_COLUMN_DEFINITIONS.map(color => (
            <div
              className={`checkbox ${
                lineColors.find(lineColor => lineColor === color.key)
                  ? 'active'
                  : ''
              }`}
              key={color.key}
              onClick={() => updateLineColor(color)}
            >
              <input type="checkbox" />
              <img src={color.icon} />
              <label>
                <div className="name">{color.label}</div>
                <FontAwesomeIcon
                  icon={faCheck}
                  size="xs"
                  className="check_icon"
                />
              </label>
            </div>
          ))}
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            onClick={onSubmit}
            className="btn_primary submit_button"
          >
            <FontAwesomeIcon icon={faCheck} size="xs" />
            決定
          </button>
        </div>
      </div>
    </div>
  );
};

export default LineColorModal;

// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import { useSearchParamsContext } from '../SearchParamsProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faAngleUp,
  faAngleDown,
  faCheck,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

type Props = {
  onPressBack: () => void,
  onCloseModal: () => void,
};

const ReelLineCapacityModal = ({ onPressBack, onCloseModal }: Props): Node => {
  const {
    selectedLineNylonGo,
    setSelectedLineNylonGo,
    selectedLineCapacityNylonGo,
    setSelectedLineCapacityNylonGo,
    selectedLineNylonLb,
    setSelectedLineNylonLb,
    selectedLineCapacityNylonLb,
    setSelectedLineCapacityNylonLb,
    selectedLineFluorocarbonGo,
    setSelectedLineFluorocarbonGo,
    selectedLineCapacityFluorocarbonGo,
    setSelectedLineCapacityFluorocarbonGo,
    selectedLineFluorocarbonLb,
    setSelectedLineFluorocarbonLb,
    selectedLineCapacityFluorocarbonLb,
    setSelectedLineCapacityFluorocarbonLb,
    selectedLinePeGo,
    setSelectedLinePeGo,
    selectedLineCapacityPeGo,
    setSelectedLineCapacityPeGo,
    selectedLinePeLb,
    setSelectedLinePeLb,
    selectedLineCapacityPeLb,
    setSelectedLineCapacityPeLb,
  } = useSearchParamsContext();
  const [nylonGo, setNylonGo] = useState(selectedLineNylonGo);
  const [nylonGoCapacity, setNylonGoCapacity] = useState(
    selectedLineCapacityNylonGo
  );
  const [nylonLb, setNylonLb] = useState(selectedLineNylonLb);
  const [nylonLbCapacity, setNylonLbCapacity] = useState(
    selectedLineCapacityNylonLb
  );
  const [fluorocarbonGo, setFluorocarbonGo] = useState(
    selectedLineFluorocarbonGo
  );
  const [fluorocarbonGoCapacity, setFluorocarbonGoCapacity] = useState(
    selectedLineCapacityFluorocarbonGo
  );
  const [fluorocarbonLb, setFluorocarbonLb] = useState(
    selectedLineFluorocarbonLb
  );
  const [fluorocarbonLbCapacity, setFluorocarbonLbCapacity] = useState(
    selectedLineCapacityFluorocarbonLb
  );
  const [peGo, setPeGo] = useState(selectedLinePeGo);
  const [peGoCapacity, setPeGoCapacity] = useState(selectedLineCapacityPeGo);
  const [peLb, setPeLb] = useState(selectedLinePeLb);
  const [peLbCapacity, setPeLbCapacity] = useState(selectedLineCapacityPeLb);

  const [visibleNylonGo, setVisibleNylonGo] = useState(false);
  const [visibleNylonLb, setVisibleNylonLb] = useState(false);
  const [visibleFluorocarbonGo, setVisibleFluorocarbonGo] = useState(false);
  const [visibleFluorocarbonLb, setVisibleFluorocarbonLb] = useState(false);
  const [visiblePeGo, setVisiblePeGo] = useState(false);
  const [visiblePeLb, setVisiblePeLb] = useState(false);

  const onPressClear = () => {
    setNylonGo(undefined);
    setNylonGoCapacity(undefined);
    setNylonLb(undefined);
    setNylonLbCapacity(undefined);
    setFluorocarbonGo(undefined);
    setFluorocarbonGoCapacity(undefined);
    setFluorocarbonLb(undefined);
    setFluorocarbonLbCapacity(undefined);
    setPeGo(undefined);
    setPeGoCapacity(undefined);
    setPeLb(undefined);
    setPeLbCapacity(undefined);
  };

  const onSubmit = () => {
    setSelectedLineNylonGo(nylonGo);
    setSelectedLineCapacityNylonGo(nylonGoCapacity);
    setSelectedLineNylonLb(nylonLb);
    setSelectedLineCapacityNylonLb(nylonLbCapacity);
    setSelectedLineFluorocarbonGo(fluorocarbonGo);
    setSelectedLineCapacityFluorocarbonGo(fluorocarbonGoCapacity);
    setSelectedLineFluorocarbonLb(fluorocarbonLb);
    setSelectedLineCapacityFluorocarbonLb(fluorocarbonLbCapacity);
    setSelectedLinePeGo(peGo);
    setSelectedLineCapacityPeGo(peGoCapacity);
    setSelectedLinePeLb(peLb);
    setSelectedLineCapacityPeLb(peLbCapacity);
    onPressBack();
  };

  const onPressNylonGo = () => {
    setVisibleNylonGo(!visibleNylonGo);
  };
  const onPressNylonLb = () => {
    setVisibleNylonLb(!visibleNylonLb);
  };
  const onPressFluorocarbonGo = () => {
    setVisibleFluorocarbonGo(!visibleFluorocarbonGo);
  };
  const onPressFluorocarbonLb = () => {
    setVisibleFluorocarbonLb(!visibleFluorocarbonLb);
  };
  const onPressPeGo = () => {
    setVisiblePeGo(!visiblePeGo);
  };
  const onPressPeLb = () => {
    setVisiblePeLb(!visiblePeLb);
  };

  return (
    <div data-scope-path="tacklebox/common/height_modal">
      <div className={`modal modal-active`}>
        <div className="modal-header">
          <div onClick={onPressBack} className="modal-back">
            <FontAwesomeIcon
              icon={faAngleLeft}
              size="xs"
              className="angle_left_icon"
            />
            戻る
          </div>
          <div>リール-糸巻き量を選択</div>
          <FontAwesomeIcon
            icon={faXmark}
            size="xs"
            className="close_icon"
            onClick={() => onCloseModal()}
          />
        </div>
        <div className="modal-body">
          <div className="display_flex flex_wrap align_center fz_sub fc_sub">
            <span onClick={onPressClear} className="fc_sub">
              <i className="fa fa-times fz_sub-headline" aria-hidden="true"></i>
              条件をクリア
            </span>
          </div>
        </div>
        <div className="modal-scroll-body">
          <div className="display_flex flex_wrap inner">
            <div className="checkbox" onClick={onPressNylonGo}>
              <div className="list_label">ナイロン(号)から探す</div>
              <div className="selected_values">
                <FontAwesomeIcon
                  icon={visibleNylonGo ? faAngleUp : faAngleDown}
                  size="xs"
                  className="right_icon"
                />
              </div>
            </div>
            {visibleNylonGo && (
              <>
                <div className="filter-list">
                  <div className="list_label">号数</div>
                  <div className="pure-u-1">
                    <input
                      value={nylonGo}
                      type="number"
                      placeholder="号"
                      onChange={e => setNylonGo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="filter-list">
                  <div className="list_label">総糸巻き量</div>
                  <div className="pure-u-1">
                    <input
                      value={nylonGoCapacity}
                      type="number"
                      placeholder="m"
                      onChange={e => setNylonGoCapacity(e.target.value)}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="display_flex flex_wrap inner">
            <div className="checkbox" onClick={onPressNylonLb}>
              <div className="list_label">ナイロン/ポンドから探す</div>
              <div className="selected_values">
                <FontAwesomeIcon
                  icon={visibleNylonLb ? faAngleUp : faAngleDown}
                  size="xs"
                  className="right_icon"
                />
              </div>
            </div>
            {visibleNylonLb && (
              <>
                <div className="filter-list">
                  <div className="list_label">ポンド</div>
                  <div className="pure-u-1">
                    <input
                      value={nylonLb}
                      type="number"
                      placeholder="lb"
                      onChange={e => setNylonLb(e.target.value)}
                    />
                  </div>
                </div>
                <div className="filter-list">
                  <div className="list_label">総糸巻き量</div>
                  <div className="pure-u-1">
                    <input
                      value={nylonLbCapacity}
                      type="number"
                      placeholder="m"
                      onChange={e => setNylonLbCapacity(e.target.value)}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="display_flex flex_wrap inner">
            <div className="checkbox" onClick={onPressFluorocarbonGo}>
              <div className="list_label">フロロ(号)から探す</div>
              <div className="selected_values">
                <FontAwesomeIcon
                  icon={visibleFluorocarbonGo ? faAngleUp : faAngleDown}
                  size="xs"
                  className="right_icon"
                />
              </div>
            </div>
            {visibleFluorocarbonGo && (
              <>
                <div className="filter-list">
                  <div className="list_label">号数</div>
                  <div className="pure-u-1">
                    <input
                      value={fluorocarbonGo}
                      type="number"
                      placeholder="号"
                      onChange={e => setFluorocarbonGo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="filter-list">
                  <div className="list_label">総糸巻き量</div>
                  <div className="pure-u-1">
                    <input
                      value={fluorocarbonGoCapacity}
                      type="number"
                      placeholder="m"
                      onChange={e => setFluorocarbonGoCapacity(e.target.value)}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="display_flex flex_wrap inner">
            <div className="checkbox" onClick={onPressFluorocarbonLb}>
              <div className="list_label">フロロ/ポンドから探す</div>
              <div className="selected_values">
                <FontAwesomeIcon
                  icon={visibleFluorocarbonLb ? faAngleUp : faAngleDown}
                  size="xs"
                  className="right_icon"
                />
              </div>
            </div>
            {visibleFluorocarbonLb && (
              <>
                <div className="filter-list">
                  <div className="list_label">ポンド</div>
                  <div className="pure-u-1">
                    <input
                      value={fluorocarbonLb}
                      type="number"
                      placeholder="lb"
                      onChange={e => setFluorocarbonLb(e.target.value)}
                    />
                  </div>
                </div>
                <div className="filter-list">
                  <div className="list_label">総糸巻き量</div>
                  <div className="pure-u-1">
                    <input
                      value={fluorocarbonLbCapacity}
                      type="number"
                      placeholder="m"
                      onChange={e => setFluorocarbonLbCapacity(e.target.value)}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="display_flex flex_wrap inner">
            <div className="checkbox" onClick={onPressPeGo}>
              <div className="list_label">PE(号)から探す</div>
              <div className="selected_values">
                <FontAwesomeIcon
                  icon={visiblePeGo ? faAngleUp : faAngleDown}
                  size="xs"
                  className="right_icon"
                />
              </div>
            </div>
            {visiblePeGo && (
              <>
                <div className="filter-list">
                  <div className="list_label">号数</div>
                  <div className="pure-u-1">
                    <input
                      value={peGo}
                      type="number"
                      placeholder="号"
                      onChange={e => setPeGo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="filter-list">
                  <div className="list_label">総糸巻き量</div>
                  <div className="pure-u-1">
                    <input
                      value={peGoCapacity}
                      type="number"
                      placeholder="m"
                      onChange={e => setPeGoCapacity(e.target.value)}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="display_flex flex_wrap inner">
            <div className="checkbox" onClick={onPressPeLb}>
              <div className="list_label">PE/ポンドから探す</div>
              <div className="selected_values">
                <FontAwesomeIcon
                  icon={visiblePeLb ? faAngleUp : faAngleDown}
                  size="xs"
                  className="right_icon"
                />
              </div>
            </div>
            {visiblePeLb && (
              <>
                <div className="filter-list">
                  <div className="list_label">ポンド</div>
                  <div className="pure-u-1">
                    <input
                      value={peLb}
                      type="number"
                      placeholder="lb"
                      onChange={e => setPeLb(e.target.value)}
                    />
                  </div>
                </div>
                <div className="filter-list">
                  <div className="list_label">総糸巻き量</div>
                  <div className="pure-u-1">
                    <input
                      value={peLbCapacity}
                      type="number"
                      placeholder="m"
                      onChange={e => setPeLbCapacity(e.target.value)}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            onClick={onSubmit}
            className="btn_primary submit_button"
          >
            <FontAwesomeIcon icon={faCheck} size="xs" />
            決定
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReelLineCapacityModal;

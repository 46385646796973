// @flow
import AxiosClient from '../AxiosClient';
import type { AxiosPromise } from 'axios';

export default class LineFavoriteClient extends AxiosClient {
  addFavorite(id: number, token: string): AxiosPromise<any> {
    return this.buildClient(token).put(`/tackle_lines/${id}/favorite.json`);
  }

  removeFavorite(id: number, token: string): AxiosPromise<any> {
    return this.buildClient(token).delete(`/tackle_lines/${id}/favorite.json`);
  }
}

// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  score?: number,
  starSize?: string,
  reviewsCount?: number,
};

function Stars({ score = 0, reviewsCount = 0, starSize = 'ul' }: Props): Node {
  const roundScore = Math.round(score * 10) / 10 || 0;
  const starIconClass = (score: number, index: number) => {
    if (score >= index) {
      return 'fa-star';
    } else if (score < index && score > index - 1) {
      return 'fa-star-half-o';
    } else {
      return 'fa-star-o';
    }
  };

  return (
    <div
      data-scope-path="tacklebox/reviews/stars_component"
      className={`rating_star_${starSize ? starSize : ''}`}
    >
      <div className="display_flex align_end">
        <div
          className={`display_flex align_end ${
            roundScore > 0 ? '' : 'no_score'
          }`}
        >
          {Array.from({ length: 5 }, (_, i) => i + 1).map(i => (
            <i
              className={`fa ${starIconClass(roundScore, i)}`}
              aria-hidden="true"
              key={i}
            ></i>
          ))}
          <span className="score">{roundScore}</span>
        </div>
        {reviewsCount > 0 && (
          <span className="fz_notice fc_sub ml_2 weight_normal review_count">
            ({reviewsCount})
          </span>
        )}
      </div>
    </div>
  );
}
export default Stars;

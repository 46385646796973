// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import { useModalContext } from './ModalProvider';
import { useSearchParamsContext } from './SearchParamsProvider';
import {
  TACKLE_LINE_MATERIAL_COLUMN_DEFINITIONS,
  TACKLE_LINE_COLOR_COLUMN_DEFINITIONS,
} from '../../models/tackle_lines/TackleLine';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleRight,
  faSliders,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import MakerModal from './MakerModal';
import PriceRangeModal from './PriceRangeModal';
import FishingMethodModal from './FishingMethodModal';
import ScoreModal from './ScoreModal';
import HeightModal from './rods/HeightModal';
import HeightMinModal from './rods/HeightMinModal';
import PieceModal from './rods/PieceModal';
import WeightModal from './rods/WeightModal';
import DragPowerModal from './reels/DragPowerModal';
import ReelLineCapacityModal from './reels/ReelLineCapacityModal';
import LineSizeModal from './lines/LineSizeModal';
import LineCategoryModal from './lines/LineCategoryModal';
import LineCapacityModal from './lines/LineCapacityModal';
import LineColorModal from './lines/LineColorModal';

const FilterModal = (): Node => {
  const [selectedFilterType, setSelectedFilterType] = useState(null);
  const [visibleMoreFilter, setVisibleMoreFilter] = useState(null);
  const { visibleFilterModal, setVisibleFilterModal } = useModalContext();
  const {
    tackleType,
    selectedMakerNames,
    selectedPriceRange,
    selectedFishingMethodNames,
    setSelectedMakers,
    setSelectedMakerNames,
    setSelectedPriceRange,
    setSelectedFishingMethods,
    setSelectedFishingMethodNames,
    // 評価
    selectedScore,
    // ロッド
    selectedRodHeightM,
    setSelectedRodHeightM,
    selectedRodHeightFt,
    setSelectedRodHeightFt,
    selectedRodHeightMin,
    setSelectedRodHeightMin,
    selectedRodPiece,
    setSelectedRodPiece,
    selectedRodWeight,
    setSelectedRodWeight,
    // リール
    selectedDragPower,
    setSelectedDragPower,
    selectedLineNylonGo,
    setSelectedLineNylonGo,
    selectedLineCapacityNylonGo,
    setSelectedLineCapacityNylonGo,
    selectedLineNylonLb,
    setSelectedLineNylonLb,
    selectedLineCapacityNylonLb,
    setSelectedLineCapacityNylonLb,
    selectedLineFluorocarbonGo,
    setSelectedLineFluorocarbonGo,
    selectedLineCapacityFluorocarbonGo,
    setSelectedLineCapacityFluorocarbonGo,
    selectedLineFluorocarbonLb,
    setSelectedLineFluorocarbonLb,
    selectedLineCapacityFluorocarbonLb,
    setSelectedLineCapacityFluorocarbonLb,
    selectedLinePeGo,
    setSelectedLinePeGo,
    selectedLineCapacityPeGo,
    setSelectedLineCapacityPeGo,
    selectedLinePeLb,
    setSelectedLinePeLb,
    selectedLineCapacityPeLb,
    setSelectedLineCapacityPeLb,
    // ライン
    selectedLineCategories,
    setSelectedLineCategories,
    selectedLineSizeGo,
    setSelectedLineSizeGo,
    selectedLineSizeLb,
    setSelectedLineSizeLb,
    selectedLineCapacity,
    setSelectedLineCapacity,
    selectedLineColors,
    setSelectedLineColors,
    //
    formRef,
    isSearchParamChanged,
  } = useSearchParamsContext();

  const onCloseModal = () => {
    setSelectedFilterType(null);
    setVisibleFilterModal(false);
    if (formRef.current && isSearchParamChanged()) {
      formRef.current.submit();
    }
  };

  const onPressMoreFilterButton = (type: string) => {
    if (visibleMoreFilter !== type) {
      setVisibleMoreFilter(type);
    } else {
      setVisibleMoreFilter(null);
    }
  };

  const onSubmit = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  const onPressBack = () => {
    setSelectedFilterType(null);
  };

  const onPressClear = () => {
    setSelectedFishingMethods([]);
    setSelectedFishingMethodNames([]);
    setSelectedMakers([]);
    setSelectedMakerNames([]);
    setSelectedPriceRange({ max: undefined, min: undefined });
    setSelectedRodHeightM({ max: undefined, min: undefined });
    setSelectedRodHeightFt({ max: undefined, min: undefined });
    setSelectedRodHeightMin({ max: undefined, min: undefined });
    setSelectedRodPiece({ max: undefined, min: undefined });
    setSelectedRodWeight({ max: undefined, min: undefined });
    setSelectedDragPower({ max: undefined, min: undefined });
    setSelectedLineNylonGo(undefined);
    setSelectedLineCapacityNylonGo(undefined);
    setSelectedLineNylonLb(undefined);
    setSelectedLineCapacityNylonLb(undefined);
    setSelectedLineFluorocarbonGo(undefined);
    setSelectedLineCapacityFluorocarbonGo(undefined);
    setSelectedLineFluorocarbonLb(undefined);
    setSelectedLineCapacityFluorocarbonLb(undefined);
    setSelectedLinePeGo(undefined);
    setSelectedLineCapacityPeGo(undefined);
    setSelectedLinePeLb(undefined);
    setSelectedLineCapacityPeLb(undefined);
    setSelectedLineCategories([]);
    setSelectedLineSizeGo({ max: undefined, min: undefined });
    setSelectedLineSizeLb({ max: undefined, min: undefined });
    setSelectedLineCapacity({ max: undefined, min: undefined });
    setSelectedLineColors([]);
  };

  let headerLable = '絞り込み検索';
  if (selectedFilterType === 'Maker') {
    headerLable = 'メーカーを選択';
  } else if (selectedFilterType === 'PriceRange') {
    headerLable = '価格帯で絞り込む';
  } else if (selectedFilterType === 'FishingMethod') {
    headerLable = '釣り方を選択';
  }

  const displayPriceRange = `${
    selectedPriceRange.min ? selectedPriceRange.min : '下限なし'
  } 〜 ${selectedPriceRange.max ? selectedPriceRange.max : '上限なし'}`;
  const displayScore = `${
    selectedScore.min ? '星' + selectedScore.min : '下限なし'
  } 〜 ${selectedScore.max ? '星' + selectedScore.max : '上限なし'}`;
  // Rod
  const displayRodHeightM = `${
    selectedRodHeightM.min ? selectedRodHeightM.min + 'm' : '下限なし'
  } 〜 ${selectedRodHeightM.max ? selectedRodHeightM.max + 'm' : '上限なし'}`;
  const displayRodHeightFt = `${
    selectedRodHeightFt.min ? selectedRodHeightFt.min + 'ft' : '下限なし'
  } 〜 ${
    selectedRodHeightFt.max ? selectedRodHeightFt.max + 'ft' : '上限なし'
  }`;
  const displayRodHeightMin = `${
    selectedRodHeightMin.min ? selectedRodHeightMin.min + 'cm' : '下限なし'
  } 〜 ${
    selectedRodHeightMin.max ? selectedRodHeightMin.max + 'cm' : '上限なし'
  }`;
  const displayRodPiece = `${
    selectedRodPiece.min ? selectedRodPiece.min + '本' : '下限なし'
  } 〜 ${selectedRodPiece.max ? selectedRodPiece.max + '本' : '上限なし'}`;
  const displayRodWeight = `${
    selectedRodWeight.min ? selectedRodWeight.min + 'g' : '下限なし'
  } 〜 ${selectedRodWeight.max ? selectedRodWeight.max + 'g' : '上限なし'}`;
  // Reel
  const displayDragPower = `${
    selectedDragPower.min ? selectedDragPower.min + 'kg' : '下限なし'
  } 〜 ${selectedDragPower.max ? selectedDragPower.max + 'kg' : '上限なし'}`;
  const displayNylonGo = `${
    selectedLineNylonGo ? selectedLineNylonGo + '号' : ''
  }、 ${selectedLineCapacityNylonGo ? selectedLineCapacityNylonGo + 'm' : ''}`;
  const displayNylonLb = `${
    selectedLineNylonLb ? selectedLineNylonLb + 'lb' : ''
  }、 ${selectedLineCapacityNylonLb ? selectedLineCapacityNylonLb + 'm' : ''}`;
  const displayFluorocarbonGo = `${
    selectedLineFluorocarbonGo ? selectedLineFluorocarbonGo + '号' : ''
  }、 ${
    selectedLineCapacityFluorocarbonGo
      ? selectedLineCapacityFluorocarbonGo + 'm'
      : ''
  }`;
  const displayFluorocarbonLb = `${
    selectedLineFluorocarbonLb ? selectedLineFluorocarbonLb + 'lb' : ''
  }、 ${
    selectedLineCapacityFluorocarbonLb
      ? selectedLineCapacityFluorocarbonLb + 'm'
      : ''
  }`;
  const displayPeGo = `${selectedLinePeGo ? selectedLinePeGo + '号' : ''}、 ${
    selectedLineCapacityPeGo ? selectedLineCapacityPeGo + 'm' : ''
  }`;
  const displayPeLb = `${selectedLinePeLb ? selectedLinePeLb + 'lb' : ''}、 ${
    selectedLineCapacityPeLb ? selectedLineCapacityPeLb + 'm' : ''
  }`;
  // ライン
  const categories = selectedLineCategories
    .map(
      lineCategory =>
        TACKLE_LINE_MATERIAL_COLUMN_DEFINITIONS.filter(
          material => material.key === lineCategory
        )[0].label
    )
    .join('、');
  const category = TACKLE_LINE_MATERIAL_COLUMN_DEFINITIONS.find(
    material => material.key === selectedLineCategories[0]
  )?.label;
  const displayLineCategory =
    selectedLineCategories.length === 1 ? category : categories;
  const displayLineSizeGo = `${
    selectedLineSizeGo.min ? selectedLineSizeGo.min + '号' : '下限なし'
  } 〜 ${selectedLineSizeGo.max ? selectedLineSizeGo.max + '号' : '上限なし'}`;
  const displayLineSizeLb = `${
    selectedLineSizeLb.min ? selectedLineSizeLb.min + 'lb' : '下限なし'
  } 〜 ${selectedLineSizeLb.max ? selectedLineSizeLb.max + 'lb' : '上限なし'}`;
  const displayLineCapacity = `${
    selectedLineCapacity.min ? selectedLineCapacity.min + 'm' : '下限なし'
  } 〜 ${
    selectedLineCapacity.max ? selectedLineCapacity.max + 'm' : '上限なし'
  }`;
  const colors = selectedLineColors
    .map(
      lineColor =>
        TACKLE_LINE_COLOR_COLUMN_DEFINITIONS.filter(
          color => color.key === lineColor
        )[0].label
    )
    .join('、');
  const color = TACKLE_LINE_COLOR_COLUMN_DEFINITIONS.find(
    lineColor => lineColor.key === selectedLineColors[0]
  )?.label;
  const displayLineColor = selectedLineColors.length === 1 ? color : colors;

  return (
    <div data-scope-path="tacklebox/common/modal">
      <div
        className={`back-drop ${visibleFilterModal ? 'back-drop-active' : ''}`}
        onClick={() => onCloseModal()}
      />
      {selectedFilterType === null && (
        <>
          <div className={`modal ${visibleFilterModal ? 'modal-active' : ''}`}>
            <div className="modal-header">
              <FontAwesomeIcon
                icon={faAngleDown}
                size="xs"
                className="drop_down_icon modal-back"
                onClick={() => onCloseModal()}
              />
              {headerLable}
              <FontAwesomeIcon
                icon={faXmark}
                size="xs"
                className="close_icon"
                onClick={() => onCloseModal()}
              />
            </div>
            <div className="modal-body">
              <div className="display_flex flex_wrap align_center fz_sub fc_sub">
                <span onClick={() => onPressClear()} className="fc_sub">
                  <i
                    className="fa fa-times fz_sub-headline"
                    aria-hidden="true"
                  ></i>
                  条件をクリア
                </span>
              </div>
            </div>
            <div className="modal-scroll-body">
              <div className="display_flex flex_wrap inner">
                <div
                  className="maker-checkbox"
                  onClick={() => setSelectedFilterType('Maker')}
                >
                  <div className="list_label">メーカー</div>
                  <div className="selected_values">
                    {selectedMakerNames.join('/')}
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      size="xs"
                      className="right_icon"
                    />
                  </div>
                </div>
                <div
                  className="price-range-checkbox"
                  onClick={() => setSelectedFilterType('PriceRange')}
                >
                  <div className="list_label">価格帯</div>
                  <div className="selected_values">
                    {displayPriceRange}
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      size="xs"
                      className="right_icon"
                    />
                  </div>
                </div>
                <div
                  className="fishing-method-checkbox"
                  onClick={() => setSelectedFilterType('FishingMethod')}
                >
                  <div className="list_label">釣り方</div>
                  <div className="selected_values">
                    {selectedFishingMethodNames.join('/')}
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      size="xs"
                      className="right_icon"
                    />
                  </div>
                </div>
                {tackleType !== 'lure' && (
                  <div
                    className="checkbox"
                    onClick={() => setSelectedFilterType('Score')}
                  >
                    <div className="list_label">評価</div>
                    <div className="selected_values">
                      {displayScore}
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        size="xs"
                        className="right_icon"
                      />
                    </div>
                  </div>
                )}
              </div>
              {tackleType !== 'lure' && (
                <div className="modal-body">
                  <div className="display_flex flex_wrap align_center fz_sub fc_sub">
                    こだわり条件
                  </div>
                </div>
              )}
              <div className="display_flex flex_wrap inner">
                {tackleType === 'rod' && (
                  <div
                    className="more-filter"
                    onClick={() => onPressMoreFilterButton('Rod')}
                  >
                    <div className="list_label">ロッド</div>
                    <div className="selected_values">
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        size="xs"
                        className="right_icon"
                      />
                    </div>
                  </div>
                )}
                {visibleMoreFilter === 'Rod' && (
                  <>
                    <div
                      className="more-filter-list"
                      onClick={() => setSelectedFilterType('RodHeight')}
                    >
                      <div className="more_filter_list_label">全長</div>
                      <div className="selected_values">
                        {(selectedRodHeightM.min || selectedRodHeightM.max) &&
                          displayRodHeightM}
                        {(selectedRodHeightFt.min || selectedRodHeightFt.max) &&
                          displayRodHeightFt}
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          size="xs"
                          className="right_icon"
                        />
                      </div>
                    </div>
                    <div
                      className="more-filter-list"
                      onClick={() => setSelectedFilterType('RodMinHeight')}
                    >
                      <div className="more_filter_list_label">仕舞寸法</div>
                      <div className="selected_values">
                        {(selectedRodHeightMin.min ||
                          selectedRodHeightMin.max) &&
                          displayRodHeightMin}
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          size="xs"
                          className="right_icon"
                        />
                      </div>
                    </div>
                    <div
                      className="more-filter-list"
                      onClick={() => setSelectedFilterType('RodPieces')}
                    >
                      <div className="more_filter_list_label">総本数</div>
                      <div className="selected_values">
                        {(selectedRodPiece.min || selectedRodPiece.max) &&
                          displayRodPiece}
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          size="xs"
                          className="right_icon"
                        />
                      </div>
                    </div>
                    <div
                      className="more-filter-list"
                      onClick={() => setSelectedFilterType('RodWeight')}
                    >
                      <div className="more_filter_list_label">重量</div>
                      <div className="selected_values">
                        {(selectedRodWeight.min || selectedRodWeight.max) &&
                          displayRodWeight}
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          size="xs"
                          className="right_icon"
                        />
                      </div>
                    </div>
                  </>
                )}

                {tackleType === 'reel' && (
                  <div
                    className="more-filter"
                    onClick={() => onPressMoreFilterButton('Reel')}
                  >
                    <div className="list_label">リール</div>
                    <div className="selected_values">
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        size="xs"
                        className="right_icon"
                      />
                    </div>
                  </div>
                )}
                {visibleMoreFilter === 'Reel' && (
                  <>
                    <div
                      className="more-filter-list"
                      onClick={() => setSelectedFilterType('ReelDragPower')}
                    >
                      <div className="more_filter_list_label">最大ドラグ力</div>
                      <div className="selected_values">
                        {(selectedDragPower.min || selectedDragPower.max) &&
                          displayDragPower}
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          size="xs"
                          className="right_icon"
                        />
                      </div>
                    </div>
                    <div
                      className="more-filter-list"
                      onClick={() => setSelectedFilterType('ReelCapacity')}
                    >
                      <div className="more_filter_list_label">糸巻き量</div>
                      <div className="selected_values">
                        {(selectedLineNylonGo || selectedLineCapacityNylonGo) &&
                          displayNylonGo}
                        {(selectedLineNylonLb || selectedLineCapacityNylonLb) &&
                          displayNylonLb}
                        {(selectedLineFluorocarbonGo ||
                          selectedLineCapacityFluorocarbonGo) &&
                          displayFluorocarbonGo}
                        {(selectedLineFluorocarbonLb ||
                          selectedLineCapacityFluorocarbonLb) &&
                          displayFluorocarbonLb}
                        {(selectedLinePeGo || selectedLineCapacityPeGo) &&
                          displayPeGo}
                        {(selectedLinePeLb || selectedLineCapacityPeLb) &&
                          displayPeLb}
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          size="xs"
                          className="right_icon"
                        />
                      </div>
                    </div>
                  </>
                )}

                {tackleType === 'line' && (
                  <div
                    className="more-filter"
                    onClick={() => onPressMoreFilterButton('Line')}
                  >
                    <div className="list_label">ライン</div>
                    <div className="selected_values">
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        size="xs"
                        className="right_icon"
                      />
                    </div>
                  </div>
                )}
                {visibleMoreFilter === 'Line' && (
                  <>
                    <div
                      className="more-filter-list"
                      onClick={() => setSelectedFilterType('LineCategory')}
                    >
                      <div className="more_filter_list_label">素材</div>
                      <div className="selected_values">
                        {displayLineCategory}
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          size="xs"
                          className="right_icon"
                        />
                      </div>
                    </div>
                    <div
                      className="more-filter-list"
                      onClick={() => setSelectedFilterType('LineSize')}
                    >
                      <div className="more_filter_list_label">
                        号数（ポンド数）
                      </div>
                      <div className="selected_values">
                        {(selectedLineSizeGo.min || selectedLineSizeGo.max) &&
                          displayLineSizeGo}
                        {(selectedLineSizeLb.min || selectedLineSizeLb.max) &&
                          displayLineSizeLb}
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          size="xs"
                          className="right_icon"
                        />
                      </div>
                    </div>
                    <div
                      className="more-filter-list"
                      onClick={() => setSelectedFilterType('LineCapacity')}
                    >
                      <div className="more_filter_list_label">巻き量</div>
                      <div className="selected_values">
                        {(selectedLineCapacity.min ||
                          selectedLineCapacity.max) &&
                          displayLineCapacity}
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          size="xs"
                          className="right_icon"
                        />
                      </div>
                    </div>
                    <div
                      className="more-filter-list"
                      onClick={() => setSelectedFilterType('LineColor')}
                    >
                      <div className="more_filter_list_label">カラー</div>
                      <div className="selected_values">
                        {displayLineColor}
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          size="xs"
                          className="right_icon"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn_primary" onClick={onSubmit}>
                <FontAwesomeIcon icon={faSliders} size="xs" />
                この条件で絞り込む
              </button>
            </div>
          </div>
        </>
      )}

      {selectedFilterType === 'Maker' && (
        <MakerModal onPressBack={onPressBack} onCloseModal={onCloseModal} />
      )}
      {selectedFilterType === 'PriceRange' && (
        <PriceRangeModal
          onPressBack={onPressBack}
          onCloseModal={onCloseModal}
        />
      )}
      {selectedFilterType === 'FishingMethod' && (
        <FishingMethodModal
          onPressBack={onPressBack}
          onCloseModal={onCloseModal}
        />
      )}

      {selectedFilterType === 'Score' && (
        <ScoreModal onPressBack={onPressBack} onCloseModal={onCloseModal} />
      )}
      {selectedFilterType === 'RodHeight' && (
        <HeightModal onPressBack={onPressBack} onCloseModal={onCloseModal} />
      )}
      {selectedFilterType === 'RodMinHeight' && (
        <HeightMinModal onPressBack={onPressBack} onCloseModal={onCloseModal} />
      )}
      {selectedFilterType === 'RodPieces' && (
        <PieceModal onPressBack={onPressBack} onCloseModal={onCloseModal} />
      )}
      {selectedFilterType === 'RodWeight' && (
        <WeightModal onPressBack={onPressBack} onCloseModal={onCloseModal} />
      )}
      {selectedFilterType === 'ReelDragPower' && (
        <DragPowerModal onPressBack={onPressBack} onCloseModal={onCloseModal} />
      )}
      {selectedFilterType === 'ReelCapacity' && (
        <ReelLineCapacityModal
          onPressBack={onPressBack}
          onCloseModal={onCloseModal}
        />
      )}
      {selectedFilterType === 'LineCategory' && (
        <LineCategoryModal
          onPressBack={onPressBack}
          onCloseModal={onCloseModal}
        />
      )}
      {selectedFilterType === 'LineSize' && (
        <LineSizeModal onPressBack={onPressBack} onCloseModal={onCloseModal} />
      )}
      {selectedFilterType === 'LineCapacity' && (
        <LineCapacityModal
          onPressBack={onPressBack}
          onCloseModal={onCloseModal}
        />
      )}
      {selectedFilterType === 'LineColor' && (
        <LineColorModal onPressBack={onPressBack} onCloseModal={onCloseModal} />
      )}
    </div>
  );
};

export default FilterModal;

// @flow
import type { Maker } from '../makers/Maker';
import type { Series } from '../series/Series';
import type { FishingMethod } from '../fishing_methods/FishingMethod';

export type TackleReel = {
  id: number,
  series: Series,
  maker: Maker,
  name_en: string,
  name_jp: ?string,
  release_year: ?number,
  series_release_year: ?number,
  price: ?number,
  is_open_pricing: boolean,
  lowest_price: ?number,
  url: ?string,
  jan_code: ?string,
  weight: ?number,
  gear_ratio: ?number,
  drag_power: ?number,
  drag_power_max: ?number,
  brake_power_max: ?number,
  spool_dia: ?number,
  spool_stroke: ?number,
  spool_material: ?string,
  handle_length: ?number,
  ball_bearing: ?number,
  roller_bearing: ?number,
  a_rb_bearing: ?number,
  sa_rb_bearing: ?number,
  winding_length: ?number,
  winding_power_max: ?number,
  winding_endurance: ?number,
  winding_speed1: ?number,
  winding_speed2: ?number,
  winding_speed3: ?number,
  winding_speed_max: ?number,
  jafs_winding_power: ?number,
  jafs_winding_speed: ?number,
  shimano_winding_power: ?number,
  handle_knob_spec: ?string,
  line_capacity_nylon_go1: ?string,
  line_capacity_nylon_go2: ?string,
  line_capacity_nylon_go3: ?string,
  line_capacity_nylon_go4: ?string,
  line_capacity_nylon_lb1: ?string,
  line_capacity_nylon_lb2: ?string,
  line_capacity_nylon_lb3: ?string,
  line_capacity_nylon_lb4: ?string,
  line_capacity_nylon_lb5: ?string,
  line_capacity_fluorocarbon_go1: ?string,
  line_capacity_fluorocarbon_go2: ?string,
  line_capacity_fluorocarbon_go3: ?string,
  line_capacity_fluorocarbon_go4: ?string,
  line_capacity_fluorocarbon_lb1: ?string,
  line_capacity_fluorocarbon_lb2: ?string,
  line_capacity_fluorocarbon_lb3: ?string,
  line_capacity_fluorocarbon_lb4: ?string,
  line_capacity_pe_go1: ?string,
  line_capacity_pe_go2: ?string,
  line_capacity_pe_go3: ?string,
  line_capacity_pe_go4: ?string,
  line_capacity_pe_go5: ?string,
  line_capacity_sub_spool_nylon_go1: ?string,
  line_capacity_sub_spool_nylon_go2: ?string,
  line_capacity_sub_spool_fluorocarbon_go1: ?string,
  line_capacity_sub_spool_fluorocarbon_go2: ?string,
  under_spool_line: ?string,
  mounting_hole_mm: ?string,
  color: ?string,
  yield_strength: ?number,
  meta_keywords: ?string,
  description: ?string,
  use_in_tackle_db: boolean,
  is_editable: boolean,
  results_count: number,
  reviews_count: number,
  total_score: ?number,
  ease_score: ?number,
  toughness_score: ?number,
  cost_score: ?number,
  latest_caught_at: ?string,
  remarks: ?string,
  fishing_methods: Array<FishingMethod>,
  sell_at_base: boolean,
  base_product_id: ?number,
  sort_order: number,
};

export const TACKLE_REEL_COLUMN_DEFINITIONS: Array<{
  key: string,
  label: string,
}> = [
  { key: 'name_en', label: 'モデル名\n（番手）' },
  { key: 'lowest_price', label: '最安値' },
  { key: 'series_release_year', label: 'シリーズ\n発売年度' },
  { key: 'weight', label: '自重（g）' },
  { key: 'gear_ratio', label: 'ギア比' },
  { key: 'drag_power', label: '実用ドラグ力\n（Kg）' },
  { key: 'drag_power_max', label: '最大ドラグ力\n（Kg）' },
  { key: 'brake_power_max', label: '最大ブレーキ力\n（kg）' },
  { key: 'spool_dia', label: 'スプール径\n（mm）' },
  { key: 'spool_stroke', label: 'スプール\nストローク\n（mm）' },
  { key: 'spool_material', label: 'スプール\n素材' },
  { key: 'handle_length', label: 'ハンドル長さ\n（mm）' },
  { key: 'ball_bearing', label: 'ボール\nベアリング数' },
  { key: 'roller_bearing', label: 'ローラー\nベアリング数' },
  { key: 'a_rb_bearing', label: 'A-RB' },
  { key: 'sa_rb_bearing', label: 'SA-RB' },
  { key: 'winding_length', label: '巻取り長さ\n（cm/1回転）' },
  { key: 'winding_power_max', label: '最大巻上力\n（kg）' },
  { key: 'winding_endurance', label: '実用巻上持久力\n（kg）' },
  { key: 'winding_speed1', label: '実用巻上速度\n（m/分）\n※1kg負荷時' },
  { key: 'winding_speed2', label: '実用巻上速度\n（m/分）\n※2kg負荷時' },
  { key: 'winding_speed3', label: '実用巻上速度\n（m/分）\n※3kg負荷時' },
  { key: 'winding_speed_max', label: '最大巻上速度 \n(m/分)' },
  { key: 'jafs_winding_power', label: 'JAFS基準巻上力\n（kg）' },
  { key: 'jafs_winding_speed', label: 'JAFS基準巻上速度\n（m/分）' },
  { key: 'shimano_winding_power', label: 'シマノ巻上力\n（kg）' },
  { key: 'handle_knob_spec', label: 'ノブタイプ' },
  { key: 'line_capacity_nylon_go1', label: '標準糸巻き量1\n（ナイロン号）' },
  { key: 'line_capacity_nylon_go2', label: '標準糸巻き量2\n（ナイロン号）' },
  { key: 'line_capacity_nylon_go3', label: '標準糸巻き量3\n（ナイロン号）' },
  { key: 'line_capacity_nylon_go4', label: '標準糸巻き量4\n（ナイロン号）' },
  { key: 'line_capacity_nylon_lb1', label: '標準糸巻き量1\n（ナイロンlb）' },
  { key: 'line_capacity_nylon_lb2', label: '標準糸巻き量2\n（ナイロンlb）' },
  { key: 'line_capacity_nylon_lb3', label: '標準糸巻き量3\n（ナイロンlb）' },
  { key: 'line_capacity_nylon_lb4', label: '標準糸巻き量4\n（ナイロンlb）' },
  { key: 'line_capacity_nylon_lb5', label: '標準糸巻き量5\n（ナイロンlb）' },
  {
    key: 'line_capacity_fluorocarbon_go1',
    label: '標準糸巻き量1\n（フロロ号）',
  },
  {
    key: 'line_capacity_fluorocarbon_go2',
    label: '標準糸巻き量2\n（フロロ号）',
  },
  {
    key: 'line_capacity_fluorocarbon_go3',
    label: '標準糸巻き量3\n（フロロ号）',
  },
  {
    key: 'line_capacity_fluorocarbon_go4',
    label: '標準糸巻き量4\n（フロロ号）',
  },
  {
    key: 'line_capacity_fluorocarbon_lb1',
    label: '標準糸巻き量1\n（フロロlb）',
  },
  {
    key: 'line_capacity_fluorocarbon_lb2',
    label: '標準糸巻き量2\n（フロロlb）',
  },
  {
    key: 'line_capacity_fluorocarbon_lb3',
    label: '標準糸巻き量3\n（フロロlb）',
  },
  {
    key: 'line_capacity_fluorocarbon_lb4',
    label: '標準糸巻き量4\n（フロロlb）',
  },
  { key: 'line_capacity_pe_go1', label: '標準糸巻き量1\n（PE号）' },
  { key: 'line_capacity_pe_go2', label: '標準糸巻き量2\n（PE号）' },
  { key: 'line_capacity_pe_go3', label: '標準糸巻き量3\n（PE号）' },
  { key: 'line_capacity_pe_go4', label: '標準糸巻き量4\n（PE号）' },
  { key: 'line_capacity_pe_go5', label: '標準糸巻き量5\n（PE号）' },
  {
    key: 'line_capacity_sub_spool_nylon_go1',
    label: '替スプール\n糸巻き量1\n（ナイロン号）',
  },
  {
    key: 'line_capacity_sub_spool_nylon_go2',
    label: '替スプール\n糸巻き量2\n（ナイロン号）',
  },
  {
    key: 'line_capacity_sub_spool_fluorocarbon_go1',
    label: '替スプール\n糸巻き量1\n（フロロ号）',
  },
  {
    key: 'line_capacity_sub_spool_fluorocarbon_go2',
    label: '替スプール\n糸巻き量2\n（フロロ号）',
  },
  { key: 'under_spool_line', label: 'スプール下巻ライン\n（PE号）' },
  { key: 'mounting_hole_mm', label: '取付穴径\n（mm）' },
  { key: 'color', label: 'カラー' },
  { key: 'yield_strength', label: '許容耐力\n（kg）' },
  { key: 'price', label: '希望小売価格' },
];

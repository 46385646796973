// @flow
import type { ComponentType } from 'react';
import styled from 'styled-components';
import * as variables from '../../lib/Variables';
import { viewPc } from '../../lib/Mixins';

export const BackDrop: ComponentType<{
  visible: boolean,
  onClick: boolean => void,
}> = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(23, 26, 28, 0.6);
  z-index: ${variables.z_modal - 1};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

export const Modal: ComponentType<{ visible: boolean }> = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70%;
  min-height: 300px;
  overflow: hidden;
  background: ${variables.tuna_2};
  z-index: ${variables.z_modal};
  transform: translateY(${props => (props.visible ? '0' : '100%')});
  transition: transform 0.3s ease-in-out;

  ${viewPc} {
    max-width: 640px;
    top: 50%;
    left: 50%;
    border-radius: 12px;
    transform: translate(-50%, -50%);
    visibility: ${props => (props.visible ? 'visible' : 'hidden')};
    transition: visibility 0.3s ease-in-out, opacity 0.3s;
    opacity: ${props => (props.visible ? 1 : 0)};
  }
`;

export const ModalHeader: ComponentType<{}> = styled.div`
  display: flex;
  align-items: center;
  padding: var(--spacer-5);
  font-weight: bold;
  line-height: var(--line-height-nowrap-ui);
  color: var(--lighten-font-color-sub);
  background: var(--lighten-fill-color-body);
  position: relative;

  ${viewPc} {
    line-height: var(--line-height-wrap-ui);
    padding: var(--spacer-5);
  }

  .drop_down_icon {
    font-size: ${variables.fz_6_pc};
    margin-right: var(--spacer-3);
    line-height: 0.0833333337em;
    margin-right: var(--spacer-2);
    ${viewPc} {
      display: none;
    }
  }

  .close_icon {
    display: none;
    font-size: ${variables.fz_7_pc};
    position: absolute;
    top: 50%;
    right: var(--spacer-5);
    transform: translateY(-50%);
    cursor: pointer;

    ${viewPc} {
      display: block;
    }
  }
`;

export const ModalBody: ComponentType<{}> = styled.div`
  padding: var(--spacer-5);
  border-bottom: 1px solid ${variables.tuna_3};
  &.price {
    min-height: 200px;
  }
`;

export const PriceDescription: ComponentType<{}> = styled.p`
  color: ${variables.aqua_7};
`;

export const ModalScrollBody: ComponentType<{}> = styled.div`
  background: var(--lighten-fill-color-sub);
  padding-bottom: calc(var(--spacer-12) * 2 + env(safe-area-inset-bottom));
  overflow-y: scroll;
  overflow-x: hidden;
  & .inner {
    background-color: #fff;
  }
`;

export const Checkbox: ComponentType<{}> = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  padding: var(--spacer-5);
  border-bottom: 1px solid ${variables.tuna_2};

  & > label {
    position: relative;
    width: 100%;

    & .check_icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      color: var(--lighten-font-color-link);
      display: none;
      font-size: var(--pc-font-size-headline);
    }
  }

  &.active label {
    & .check_icon {
      display: block;
    }
  }
`;

export const MakerCheckbox: ComponentType<{}> = styled(Checkbox)`
  ${viewPc} {
    width: 50%;
    &:nth-child(odd) {
      border-right: 1px solid ${variables.tuna_2};
    }
  }

  & > img {
    width: 56px;
    height: auto;
  }

  & > label {
    margin-left: var(--spacer-5);

    & .name_en {
      font-weight: bold;
      font-size: var(--pc-font-size-default);
    }
    & .name_jp {
      font-size: var(--pc-font-size-sub);
    }
  }
`;

export const FishingMethodCheckbox: ComponentType<{}> = styled(Checkbox)`
  .method_name {
    font-weight: bold;
    font-size: var(--pc-font-size-default);
  }
`;

export const ModalFooter: ComponentType<{}> = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: var(--spacer-5);
  padding-bottom: calc(var(--spacer-5) + env(safe-area-inset-bottom));
  z-index: ${variables.z_modal + 1};
  transform: translateZ(1px);
  > a {
    width: 100%;
  }
`;

// @flow
import React, { useState } from 'react';

import ReelFavoriteClient from '../../models/favorites/ReelFavoriteClient';

type Props = {
  tackleReelId: number,
  isFollowing: ?boolean,
  isSignedIn: boolean,
  token: string,
};

function ReelFarvoiteButtonRoot(props: Props) {
  return <ReelFarvoiteButton {...props} />;
}

function ReelFarvoiteButton({
  tackleReelId,
  isFollowing,
  isSignedIn,
  token,
}: Props) {
  const [following, setFollowing] = useState(isFollowing);

  const onClick = () => {
    const reelFavoriteClient = new ReelFavoriteClient();
    if (following) {
      console.log(tackleReelId);
      reelFavoriteClient
        .removeFavorite(tackleReelId, token)
        .then(() => setFollowing(false))
        .catch(error => console.error(error));
    } else {
      reelFavoriteClient
        .addFavorite(tackleReelId, token)
        .then(() => setFollowing(true))
        .catch(error => console.error(error));
    }
  };

  return (
    <>
      {isSignedIn ? (
        <a
          data-scope-path="tacklebox/commons/favorite_button_component"
          className={`ga4_click2 ${following ? 'active' : ''}`}
          onClick={onClick}
        >
          きになる
        </a>
      ) : (
        <a
          href="/users/sign_in"
          data-scope-path="tacklebox/commons/favorite_button_component"
          className="ga4_click2"
        >
          きになる
        </a>
      )}
    </>
  );
}

export default ReelFarvoiteButtonRoot;

// @flow
import React from 'react';
import type { Node } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

type Props = {
  visible: boolean,
  onClose: boolean => void,
  advertisements_count: ?number,
};

const PriceModal = ({
  visible,
  onClose,
  advertisements_count,
}: Props): Node => {
  const today = new Date();
  return (
    <div data-scope-path="tacklebox/common/modal">
      <div
        className={`back-drop ${visible ? 'back-drop-active' : ''}`}
        onClick={onClose}
      />
      <div className={`modal ${visible ? 'modal-active' : ''}`}>
        <div className="modal-header">
          <FontAwesomeIcon
            icon={faAngleDown}
            size="xs"
            className="drop_down_icon"
          />
          最安値について
        </div>
        <div className="modal-body">
          <p className="price-description">
            ※楽天市場/ amazon/ yahooショッピング 3社の価格（
            {advertisements_count ? advertisements_count : 0}
            件）を調査
            {'·'}
            {today.getMonth() + 1}月{today.getDate()}日 更新
          </p>
          <p>
            当サイトの価格は、常に変動しています。
            <br />
            時間帯によっては最新の価格と異なる場合があります。
            <br />
            そのため、ご注文を確定された時の価格と、詳細ページの価格が一致しなくなる場合があります。
          </p>
        </div>
        <div className="modal-footer">
          <a onClick={onClose} className="btn_primary">
            閉じる
          </a>
        </div>
      </div>
    </div>
  );
};

export default PriceModal;

// @flow
import React, { useState } from 'react';
import type { TackleRod } from '../../models/tackle_rods/TackleRod';
import { TACKLE_ROD_COLUMN_DEFINITIONS } from '../../models/tackle_rods/TackleRod';
import FavoriteModal from './FavoriteModal';
import RodFavoriteClient from '../../models/favorites/RodFavoriteClient';

type Props = {
  tackleRods: Array<TackleRod>,
  token: string,
};

type Columns = {
  key: string,
  label: string,
};

function RodTableRoot(props: Props) {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <RodTable {...props} />;
}

function RodTable({ tackleRods, token }: Props) {
  const [visible, setVisible] = useState<boolean>(false);
  const [rodId, setRodId] = useState<number>(0);
  const [maskedRods, setMaskedRods] = useState<Array<TackleRod>>(tackleRods);

  const onOpen = (id: number) => {
    setRodId(id);
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onRemove = (id: number) => {
    const rodFavoriteClient = new RodFavoriteClient();
    rodFavoriteClient
      .removeFavorite(id, token)
      .then(() => {
        setMaskedRods(maskedRods.filter(rod => rod.id != id));
        setVisible(false);
      })
      .catch(error => console.error(error));
  };

  const displayKeys = maskedRods.reduce((acc, rod) => {
    const entries = Object.keys(rod).filter(key => {
      return rod[key] != null && rod[key] !== '';
    });
    return Array.from(new Set([...entries, ...acc]));
  }, []);

  const displayDefinitions: Array<Columns> = TACKLE_ROD_COLUMN_DEFINITIONS.filter(
    definition => {
      return displayKeys.includes(definition.key);
    }
  );

  const rodName = (rod: TackleRod) => {
    return `${rod.series.name_jp} ${rod.name_en}`;
  };

  const rodNameColumn = (rod: TackleRod) => {
    return (
      <div className="list_control">
        <a href={`/rods/${rod.id}`} className="name">
          {rodName(rod)}
        </a>
        <a
          className="remove_list"
          onClick={() => {
            onOpen(rod.id);
          }}
        >
          <i className="las la-times"></i>
        </a>
      </div>
    );
  };

  const displayTableHead = (definitions: Array<Columns>) => {
    return (
      <tr>
        {definitions.map(definition => {
          return <th key={definition.key}>{definition.label}</th>;
        })}
      </tr>
    );
  };

  const displayTableBody = (rods: Array<TackleRod>) => {
    return (
      <>
        {rods.map(rod => {
          return (
            <tr key={rod.id}>
              {displayDefinitions.map(definition => {
                if (definition.key == 'name_en') {
                  return <td key={definition.key}>{rodNameColumn(rod)}</td>;
                } else {
                  return <td key={definition.key}>{rod[definition.key]}</td>;
                }
              })}
            </tr>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div data-scope-path="tacklebox/favorites/comparison_table">
        {(() => {
          if (maskedRods.length == 0) {
            return <p>お気に入りのロッドはありません。</p>;
          } else {
            return (
              <table>
                <thead>{displayTableHead(displayDefinitions)}</thead>
                <tbody>{displayTableBody(maskedRods)}</tbody>
              </table>
            );
          }
        })()}
      </div>
      <FavoriteModal
        visible={visible}
        onClose={onClose}
        modelId={rodId}
        onRemove={onRemove}
      />
    </>
  );
}

export default RodTableRoot;

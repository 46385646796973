// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import PriceModal from './PriceModal';

type Props = {
  advertisements_count: ?number,
};

function PriceModalTrigger(props: Props): Node {
  const [visible, setVisible] = useState<boolean>(false);

  const onOpen = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div data-scope-path="tacklebox/models/price_modal_trigger">
      <div className="headline_sub lh_nowrap_ui">
        <i className="trigger fa fa-question-circle-o" onClick={onOpen}></i>
      </div>
      <PriceModal
        visible={visible}
        onClose={onClose}
        advertisements_count={props.advertisements_count}
      />
    </div>
  );
}

export default PriceModalTrigger;

// @flow
const breakPointNum = 768;

export function breakPoint(): number {
  return breakPointNum;
}

export function viewPc(): string {
  return `@media screen and (min-width: ${breakPoint()}px)`;
}

export function viewSp(): string {
  return `@media screen and (max-width: ${breakPoint() - 1}px)`;
}

export function viewSpXs(): string {
  return `@media screen and (max-width: 320px)`;
}

// @flow
import React from 'react';
import { useModalContext } from './ModalProvider';
import { useSearchParamsContext } from './SearchParamsProvider';

const Carouseltems = (): React$Node => {
  const { toggleModal } = useModalContext();
  const {
    selectedMakerNames,
    // selectedFishNames,
    selectedFishingMethodNames,
    selectedPriceRange,
    numberToCurrency,
  } = useSearchParamsContext();
  return (
    <div data-scope-path="tacklebox/search/carousel_items">
      <div className="carousel-item" onClick={() => toggleModal('maker')}>
        {selectedMakerNames.length > 0 ? (
          <div className="filter-tag active">
            {selectedMakerNames.map(name => (
              <span key={name}>{name}</span>
            ))}
          </div>
        ) : (
          <div className="filter-tag">メーカー</div>
        )}
      </div>
      <div className="carousel-item" onClick={() => toggleModal('priceRange')}>
        {selectedPriceRange.min || selectedPriceRange.max ? (
          <div className="filter-tag active">
            {selectedPriceRange.min && (
              <span>{numberToCurrency(selectedPriceRange.min)}</span>
            )}
            〜
            {selectedPriceRange.max && (
              <span>{numberToCurrency(selectedPriceRange.max)}</span>
            )}
          </div>
        ) : (
          <div className="filter-tag">価格帯</div>
        )}
      </div>
      <div
        className="carousel-item"
        onClick={() => toggleModal('fishingMethod')}
      >
        {selectedFishingMethodNames.length > 0 ? (
          <div className="filter-tag active">
            {selectedFishingMethodNames.map(name => (
              <span key={name}>{name}</span>
            ))}
          </div>
        ) : (
          <div className="filter-tag">釣り方</div>
        )}
      </div>
      {/* フェーズ1では魚種検索しない */}
      {/* <CarouselItem onClick={() => toggleModal('fish')}>
          {selectedFishNames.length > 0 ? (
            <FilterTag className="active">
              {selectedFishNames.map(name => (
                <span key={name}>{name}</span>
              ))}
            </FilterTag>
          ) : (
            <FilterTag>魚種</FilterTag>
          )}
        </CarouselItem> */}
    </div>
  );
};

export default Carouseltems;

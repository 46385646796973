// @flow
import React from 'react';
import type { Maker } from '../../models/makers/Maker';
import type { FishingMethod } from '../../models/fishing_methods/FishingMethod';
import type { SearchModel } from '../../models/products/SearchModel';
import type { TackleType } from './TackleType';
import type { PrototypeTackleLure } from '../../models/prototype_tackle_lures/PrototypeTackleLure.js';
import ModelsCardList from './ModelsCardList.js';
import SearchBar from './SearchBar';
import FilterModal from './FilterModal';
import FilterModalButton from './FilterModalButton';
import { ModalProvider } from './ModalProvider.js';
import { SearchParamsProvider } from './SearchParamsProvider';
import bannerImage from '../../assets/images/views/search/banner.webp';
import FilterButtons from './FilterButtons.js';
import PrototypeLureCardList from './PrototypeLureCardList.js';

type Props = {
  makers: Array<Maker>,
  fishingMethods: Array<FishingMethod>,
  models: Array<SearchModel>,
  totalCount: number,
  currentPage: number,
  keywords: string | null,
  initialType: TackleType,
  tackleType: TackleType,
  lures: Array<PrototypeTackleLure>,
  rodsCount: number,
  reelsCount: number,
  linesCount: number,
  luresCount: number,
};

function SearchFormRoot(props: Props): React$Node {
  return <SearchForm {...props} />;
}

function SearchForm({
  makers,
  fishingMethods,
  models,
  totalCount,
  currentPage,
  keywords = null,
  initialType = 'lure',
  tackleType = 'lure',
  lures,
  rodsCount,
  reelsCount,
  linesCount,
  luresCount,
}: Props) {
  const fishes = [];

  const currentNumber = (currentPage, totalCount) => {
    const perPage = 32;
    const minNumber =
      currentPage > 1 ? (Number(currentPage) - 1) * perPage + 1 : 1;
    const maxNumber =
      totalCount >= minNumber + perPage ? currentPage * perPage : totalCount;

    return `${minNumber} - ${maxNumber}件`;
  };

  const searchBySeabass = (): boolean => {
    return !!keywords && keywords.includes('シーバス');
  };

  return (
    <ModalProvider>
      <SearchParamsProvider
        makers={makers}
        fishes={fishes}
        fishingMethods={fishingMethods}
        initialType={initialType}
        tackleType={tackleType}
      >
        <SearchBar>
          <div data-scope-path="react_components/tacklebox/common/search_form_component">
            {searchBySeabass() ? (
              <div className="seabass_lure_banner_top">
                <a
                  href="https://anglers.thebase.in/categories/5560974"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={bannerImage} alt="ルアーバナー" />
                </a>
              </div>
            ) : null}

            {/* 選択しているタグ一覧 */}
            <div className="filter_wrapper">
              <FilterButtons
                rodsCount={rodsCount}
                reelsCount={reelsCount}
                linesCount={linesCount}
                luresCount={luresCount}
              />
            </div>
            {totalCount > 0 ? (
              <div className="counter">
                <div className="counter_total">
                  <em>{totalCount}</em>件 見つかりました
                </div>
                <div>{currentNumber(currentPage, totalCount)}</div>
                <FilterModalButton />
              </div>
            ) : (
              <>
                <div className="filter_button_wrapper">
                  <FilterModalButton />
                </div>
                <div className="no_results">
                  お探しの条件では見つかりませんでした。
                  <br />
                  ほかの条件をお試しください！
                </div>
              </>
            )}
            {/* フェーズ1では魚種検索しない */}
            {/* <FishModal /> */}
          </div>
        </SearchBar>
        {/* モーダル */}
        <FilterModal />

        {/* 検索結果 */}
        {initialType !== 'lure' ? (
          <ModelsCardList models={models} searchBySeabass={searchBySeabass()} />
        ) : (
          <PrototypeLureCardList lures={lures} />
        )}
      </SearchParamsProvider>
    </ModalProvider>
  );
}

export default SearchFormRoot;

// @flow
import React from 'react';
import type { Maker } from '../../models/makers/Maker.js';
import type { FishingMethod } from '../../models/fishing_methods/FishingMethod.js';
import type { PrototypeTackleLure } from '../../models/prototype_tackle_lures/PrototypeTackleLure.js';
import PrototypeLureCardList from './PrototypeLureCardList.js';
import SearchBar from './SearchBar.js';
import Carouseltems from './Carouseltems.js';
import MakerModal from './MakerModal.js';
import PriceRangeModal from './PriceRangeModal.js';
import FishingMethodModal from './FishingMethodModal.js';
import { ModalProvider } from './ModalProvider.js';
import { SearchParamsProvider } from './SearchParamsProvider.js';

type Props = {
  makers: Array<Maker>,
  fishingMethods: Array<FishingMethod>,
  lures: Array<PrototypeTackleLure>,
  totalCount: number,
  currentPage: number,
};

function PrototypeLureSearchForm({
  makers,
  fishingMethods,
  lures,
  totalCount,
  currentPage,
}: Props): React$Node {
  const fishes = [];

  const currentNumber = (currentPage, totalCount) => {
    const perPage = 12;
    const minNumber =
      currentPage > 1 ? (Number(currentPage) - 1) * perPage + 1 : 1;
    const maxNumber =
      totalCount >= minNumber + perPage ? currentPage * perPage : totalCount;

    return `${minNumber} - ${maxNumber}件`;
  };

  return (
    <ModalProvider>
      <SearchParamsProvider
        makers={makers}
        fishes={fishes}
        fishingMethods={fishingMethods}
      >
        <SearchBar>
          <div data-scope-path="react_components/tacklebox/common/search_form_component">
            {/* 選択しているタグ一覧 */}
            <Carouseltems />
            {totalCount > 0 ? (
              <div className="counter">
                <div className="counter_total">
                  <em>{totalCount}</em>件 見つかりました
                </div>
                <div>{currentNumber(currentPage, totalCount)}</div>
              </div>
            ) : (
              <div className="no_results">
                お探しの条件では見つかりませんでした。
                <br />
                ほかの条件をお試しください！
              </div>
            )}
            {/* モーダル */}
            <MakerModal />
            <PriceRangeModal />
            <FishingMethodModal />
            {/* フェーズ1では魚種検索しない */}
            {/* <FishModal /> */}
          </div>
        </SearchBar>

        {/* 検索結果 */}
        <PrototypeLureCardList lures={lures} />
      </SearchParamsProvider>
    </ModalProvider>
  );
}

export default PrototypeLureSearchForm;

// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import { useSearchParamsContext } from './SearchParamsProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faCheck,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

type Props = {
  onPressBack: () => void,
  onCloseModal: () => void,
};

const PriceRangeModal = ({ onPressBack, onCloseModal }: Props): Node => {
  const {
    selectedPriceRange,
    setSelectedPriceRange,
  } = useSearchParamsContext();
  const [priceRange, setPriceRange] = useState<{
    min: ?number,
    max: ?number,
  }>({
    min: selectedPriceRange.min,
    max: selectedPriceRange.max,
  });

  const PriceList = [
    1000,
    2000,
    3000,
    4000,
    5000,
    6000,
    7000,
    8000,
    9000,
    10000,
    20000,
    30000,
    40000,
    50000,
    60000,
    70000,
    80000,
    90000,
    100000,
  ];

  const onPressClear = () => {
    setPriceRange({ min: undefined, max: undefined });
  };

  const onSubmit = () => {
    setSelectedPriceRange(priceRange);
    onPressBack();
  };

  return (
    <div data-scope-path="tacklebox/common/modal">
      <div className={`modal modal-active`}>
        <div className="modal-header">
          <div onClick={() => onPressBack()} className="modal-back">
            <FontAwesomeIcon
              icon={faAngleLeft}
              size="xs"
              className="angle_left_icon"
            />
            戻る
          </div>
          <div>価格帯で絞り込む</div>
          <FontAwesomeIcon
            icon={faXmark}
            size="xs"
            className="close_icon"
            onClick={() => onCloseModal()}
          />
        </div>
        <div className="modal-body">
          <div className="display_flex flex_wrap align_center fz_sub fc_sub">
            <span onClick={onPressClear} className="fc_sub">
              <i className="fa fa-times fz_sub-headline" aria-hidden="true"></i>
              条件をクリア
            </span>
          </div>
        </div>
        <div className="modal-body price">
          <div className="display_flex align_center">
            <div className="select_wrapper pure-u-1">
              <select
                value={priceRange.min}
                onChange={e =>
                  setPriceRange({
                    ...priceRange,
                    min: e.target.value,
                  })
                }
              >
                <option value="">下限なし</option>
                {PriceList.map(price => (
                  <option key={price} value={price}>
                    {price.toLocaleString('ja-JP')}
                  </option>
                ))}
              </select>
            </div>
            <div className="p_3">〜</div>
            <div className="select_wrapper pure-u-1">
              <select
                value={priceRange.max}
                onChange={e =>
                  setPriceRange({
                    ...priceRange,
                    max: e.target.value,
                  })
                }
              >
                <option value="">上限なし</option>
                {PriceList.map(price => (
                  <option key={price} value={price}>
                    {price.toLocaleString('ja-JP')}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            onClick={onSubmit}
            className="btn_primary submit_button"
          >
            <FontAwesomeIcon icon={faCheck} size="xs" />
            決定
          </button>
        </div>
      </div>
    </div>
  );
};

export default PriceRangeModal;
